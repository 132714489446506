<template>
  <div class="home">
    <v-progress-linear
      active
      background-opacity="1"
      buffer-value="100"
      height="4"
      indeterminate
      value="1000"
      color="#59b570"
      v-if="isFiltering"
      class="v-progress-linear"
    ></v-progress-linear>
    <div v-if="isLoading" class="loader">
      <v-progress-circular indeterminate color="#59b570"></v-progress-circular>
    </div>
    <Map
      :selectedEmsp="selectedEmsp"
      :isLoading="isLoading"
      :emspOption="emspOption"
    ></Map>
  </div>
</template>

<script>
import Map from "@/components/map/Map";
export default {
  name: "Home",
  components: {
    Map
  },
  data() {
    return {
      selectedEmsp: null
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["station/isStationLoading"];
    },
    isFiltering() {
      return this.$store.getters["emsp/isFiltering"];
    },
    emspOption() {
      let currentEmspOptions = null;
      let abonnement, prix_badge, prix_transactions;
      if (this.selectedEmsp && this.$store.getters["emsp/emspOption"].length) {
        this.$store.getters["emsp/emspOption"].forEach(emspOption => {
          if (emspOption && emspOption.acf.slug === this.selectedEmsp.emsp_id)
            currentEmspOptions = emspOption;
        });
        if (currentEmspOptions) {
          abonnement = currentEmspOptions.acf.abonnement;
          prix_badge = currentEmspOptions.acf.prix_badge;
          prix_transactions = currentEmspOptions.acf.prix_transactions;
        }
        return {
          abonnement,
          prix_badge,
          prix_transactions
        };
      }
      return {};
    }
  },
  async created() {
    const routeEmsp = this.$route.params.id;
    let currentEmsp;
    if (routeEmsp) {
      currentEmsp = await this.$store.dispatch("emsp/getOneEmsp", routeEmsp);
      if (currentEmsp) {
        this.selectedEmsp = currentEmsp;
        this.$store.commit("emsp/SET_ONE_EMSP", currentEmsp);
        await this.$store.dispatch("station/getNewStations", true);
        this.$store.commit("station/SET_SELECTED_EMSP", currentEmsp);
      } else {
        this.$router.push("/");
      }
    } else await this.$store.dispatch("station/getNewStations", false);
  }
};
</script>

<style lang="scss">
.v-progress-linear {
  position: absolute;
  top: 0;
  z-index: 1000;
}
.home {
  width: 100%;
  height: 100vh;
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    background: #2c2c32;
    width: 100%;
    height: 100%;
    .v-progress-circular {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
