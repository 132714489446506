import Vue from "vue";
import Vuex from "vuex";

import station from "./station";
import emsp from "./emsp";
import consultation from "./consultation";
import scan from "./scan";
import admin from "./admin";
import carte from "./carte";
import search from "./search";
Vue.use(Vuex);

export default new Vuex.Store({
  strict: false,
  modules: {
    station,
    emsp,
    consultation,
    scan,
    admin,
    carte,
    search
  },
  state: {},
  mutations: {},
  actions: {}
});
