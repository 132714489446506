<template>
  <section class="emsp pa-2" @click="selectEmsp">
    <!-- <h4 :class="isHovered ? 'bg-yellow' : ''">
      {{ emsp.name }} <v-icon>fas fa-arrow-right</v-icon>
    </h4> -->
    <div :class="!visible ? 'hidden' : 'active'"></div>
    <img :src="emsp.params.logo" class="logo mb-4" alt="logo emsp" />
    <h4>{{ emsp.name }}</h4>
    <v-btn
      @click.stop="$emit('show-infos', emsp)"
      rounded
      color="#ffffff"
      class="info-icon"
    >
      <img src="@/assets/map/info.svg" alt="" />
    </v-btn>
    <!-- <section class="more">
      <p>
        <a :href="emsp.signup"
          ><v-icon>fas fa-clipboard-check</v-icon>{{ $t("emsp.subscribe") }}</a
        >
      </p>
      <p>
        <a :href="emsp.signin"
          ><v-icon>fas fa-sign-in-alt</v-icon>{{ $t("emsp.login") }}</a
        >
      </p>
    </section> -->
    <v-icon class="checked" color="#59b570" v-if="isEmspSelected"
      >fas fa-check-circle</v-icon
    >
  </section>
</template>

<script>
import EventBus from "@/utils/eventBus.js";
export default {
  props: ["emsp", "visible"],
  data() {
    return {
      isHovered: false,
      isEmspSelected: false
    };
  },
  computed: {
    current() {
      return this.$store.getters["station/selectedEmsp"];
    }
  },
  created() {
    EventBus.$on("reset-emsp", emsp_id => {
      if (this.emsp.emsp_id === emsp_id) this.setIsEmspSelected(true);
      else this.setIsEmspSelected(false);
    });

    if (this.current?.emsp_id === this.emsp.emsp_id)
      this.setIsEmspSelected(true);
  },
  methods: {
    async setEmsp(emsp) {
      this.$store.commit("emsp/SET_FILTERING", emsp ? true : false);
      this.isHovered = emsp ? true : false;
      // await new Promise(resolve => setTimeout(resolve, 200));
      this.$store.commit("station/SET_SELECTED_EMSP", emsp);
    },
    selectEmsp() {
      if (this.isEmspSelected) {
        this.$emit("remove-emsp");
        this.setIsEmspSelected(false);
      } else {
        this.setIsEmspSelected(true);
        EventBus.$emit("reset-emsp", this.emsp.emsp_id);
        this.$emit("clicked-emsp", this.emsp);
      }
    },
    setIsEmspSelected(val) {
      this.isEmspSelected = val;
    }
  }
};
</script>

<style lang="scss">
.emsp {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 160px;
  border-radius: 10px;
  background: #f1f1f1;
  width: 100%;
  cursor: pointer;
  div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    opacity: 0.75;
    transition: opacity 0.25s ease;
    &.active {
      opacity: 0;
    }
  }
  .logo {
    width: 50%;
  }
  h4 {
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 0.8em;
    margin: 0;
    line-height: 1.2;
  }
  .bg-yellow {
    background: #fff200;
    color: #2c2c32;
  }
  .info-icon {
    position: absolute;
    z-index: 12;
    top: 8px;
    right: 8px;
    width: 18px;
    height: 18px !important;
    min-width: 18px !important;
    padding: 0 !important;
    //border: 1px solid #ddd !important;
    box-shadow: none; /*0px 0px 2px 0px rgba(0,0,0,.5);*/
    img {
      width: 12px;
    }
  }
  .checked {
    position: absolute !important;
    top: -5px;
    right: -5px;
    background: white;
    border-radius: 50%;
  }
}
</style>
