import gql from "graphql-tag";
export const GET_STATION_FROM_EMI3_ID = gql`
  query scanGetStation(
    $stationWhere: station_bool_exp!
    $cpWhere: charging_point_bool_exp
  ) {
    station(where: $stationWhere) {
      activation_date
      code_insee
      country
      cpo_id
      cpo_name
      create_at
      external_id
      is_public
      last_update
      latitude
      location
      longitude
      max_power
      remove_date
      soft_delete
      state
      station_id
      station_emi3
      station_name
      street_name
      street_number
      supervision_id
      town
      update_at
      update_by
      zip_code
      cpo {
        tariffs {
          cpo_id
          create_at
          description
          emsp_id
          end_at
          is_roaming
          price_elements
          soft_delete
          start_at
          update_at
          update_by
        }
      }
      charging_points(where: $cpWhere) {
        emi3
        power
        power_type
        state
        status
        type_charge
        connectors {
          amperage
          charge_box_id
          charging_point_id
          connector_connector_id
          connector_emi3
          connector_type
          create_at
          format
          last_update
          power
          power_type
          soft_delete
          standard
          connector_standard {
            name
            type
            icon
          }
          tariff
          update_at
          update_by
          voltage
        }
      }
    }
  }
`;

export const GET_CP_FROM_ID = gql`
  query scanGetCp($emi3_id: String!) {
    station(
      where: {
        charging_points: { connectors: { r_station_id: { _eq: $emi3_id } } }
      }
    ) {
      activation_date
      code_insee
      country
      cpo_id
      cpo_name
      create_at
      external_id
      is_public
      last_update
      latitude
      location
      longitude
      max_power
      remove_date
      soft_delete
      state
      station_id
      station_emi3
      station_name
      street_name
      street_number
      supervision_id
      town
      update_at
      update_by
      zip_code
      cpo {
        tariffs {
          cpo_id
          create_at
          description
          emsp_id
          end_at
          is_roaming
          price_elements
          soft_delete
          start_at
          update_at
          update_by
        }
      }
      charging_points {
        emi3
        power
        power_type
        state
        status
        type_charge
        connectors {
          amperage
          charge_box_id
          charging_point_id
          connector_connector_id
          connector_emi3
          connector_type
          create_at
          format
          last_update
          power
          power_type
          soft_delete
          standard
          tariff
          update_at
          update_by
          voltage
        }
      }
    }
  }
`;

export const GET_CONNECTOR_FROM_ID = gql`
  query scanGetConnector($id: String!) {
    connector(where: { connector_emi3: { _eq: $id } }) {
      amperage
      charge_box_id
      charging_point_id
      connector_connector_id
      connector_emi3
      connector_type
      create_at
      format
      last_update
      power
      power_type
      soft_delete
      standard
      tariff
      update_at
      update_by
      voltage
      station {
        activation_date
        code_insee
        country
        cpo_id
        cpo_name
        create_at
        external_id
        is_public
        last_update
        latitude
        location
        longitude
        max_power
        remove_date
        soft_delete
        state
        station_emi3
        station_name
        street_name
        street_number
        supervision_id
        town
        update_at
        update_by
        zip_code
      }
      charging_point {
        capabilities
        connector
        create_at
        emi3
        external_id
        last_update
        power
        power_type
        r_station_id
        soft_delete
        state
        status
        terminal_id
        type_charge
        update_at
        update_by
      }
    }
  }
`;
