import gql from "graphql-tag";

export const GET_PASSWORD_FROM_USER_TOKEN = gql`
  query getPasswordFromUserToken($token: String!) {
    user_token(where: { user_token_id: { _eq: $token } }) {
      password
      token
      user_id
      user_token_id
    }
  }
`;
