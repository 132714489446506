import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import SignIn from "../views/SignIn.vue";

Vue.use(VueRouter);
// TODO add lang prefix
const routes = [
  // {
  //   path: "/:lang",
  //   component: {
  //     render(c) {
  //       return c("router-view");
  //     }
  //   },
  //   children: [
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn
  },
  {
    path: "/scan",
    name: "Scan",
    component: () => import(/* webpackChunkName: "scan" */ "../views/Scan.vue")
  },
  {
    path: "/:id?",
    name: "Home",
    component: Home
    // meta: { layout: "app-layout" }
  },
  {
    path: "/station/:emi3/:cp_id?/:connector_id?",
    name: "ScanResult",
    component: () =>
      import(/* webpackChunkName: "ScanResult" */ "../views/ScanResult.vue")
  },

  {
    path: "/consultation/:id",
    name: "Consultation",
    component: () =>
      import(/* webpackChunkName: "consultation" */ "../views/Consultation.vue")
  },

  {
    path: "/admin/space",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Admin.vue"),
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "/",
        name: "admin",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/admin/Index.vue")
      }
    ]
  },

  {
    path: "/carte/:token",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RestrictedMap.vue")
  }

  //   ]
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// // use beforeEach route guard to set the language
// router.beforeEach((to, from, next) => {
//   // use the language from the routing param or default language
//   let language = to.params.lang;
//   if (!language) {
//     language = "fr";
//   }
//   let path = `${language}${to.fullPath}`;
//   // set the current language for vuex-i18n. note that translation data
//   // for the language might need to be loaded first
//   // Vue.$i18n.set(language);
//   next({ path });
// });

export default router;
