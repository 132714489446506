<template>
  <div v-if="results.length" :class="'search-results'">
    <v-tabs :show-arrows="false" grow icons-and-text>
      <v-tab @click="type = 'normale'">
        {{ $t("filter.normal") }}
        <v-icon class="mb-2">
          fas solid fa-plug
        </v-icon>
      </v-tab>
      <v-tab @click="type = 'rapide'">
        {{ $t("filter.fast") }}

        <v-icon class="mb-2">
          fas solid fa-bolt
        </v-icon>
      </v-tab>
    </v-tabs>

    <div v-for="(result, i) in filtered" :key="i" class="result-item">
      <SearchResultItem :data="result" />
      <v-divider v-if="i < results.length - 1"></v-divider>
    </div>
    <div
      class="search-results loading"
      v-if="
        type === 'normale' &&
          results.filter(item => item.type_charge === 'normale').length === 0
      "
    >
      <p>
        Aucune borne de recharge normale disponible
      </p>
    </div>
    <div
      class="search-results loading"
      v-if="
        type === 'rapide' &&
          results.filter(item => item.type_charge === 'rapide').length === 0
      "
    >
      <p>
        Aucune borne de recharge rapide disponible
      </p>
    </div>
  </div>
  <div v-else class="search-results loading">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
      v-if="loading"
    ></v-progress-circular>
    <p v-else>{{ $t("map.no-nearby") }}</p>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SearchResultItem from "./SearchResultItem.vue";
export default {
  components: { SearchResultItem },
  computed: {
    ...mapGetters("search", ["query", "results", "loading"]),
    ...mapGetters("station", ["selectedEmsp", "filters"]),
    filtered() {
      let results = [...this.results];
      /** Filter results by selected EMSP */
      if (this.selectedEmsp) {
        results = results.map(result => {
          let isOk = [];
          if (result.station.emsp.length) {
            isOk = result.station.emsp.map(emsp => {
              return emsp.name === this.selectedEmsp.emsp_id;
            });
            isOk = isOk.filter(item => item !== undefined);
            if (isOk.length) return result;
          }
        });
        results = results.filter(item => item !== undefined);
      }
      /** Filter results by type of charge */
      results = results.filter(item => item.type_charge === this.type);
      results.sort((a, b) => {
        return a.distance - b.distance;
      });
      /** Filter by type of plug
       * First, filter only through activated filter
       * then return only plug-type filters
       */

      const filters = Object.entries({ ...this.filters })
        .filter(item => {
          return item[1] === true;
        })
        .map(filter => {
          if (
            filter[0] === "combo" ||
            filter[0] === "domestic" ||
            filter[0] === "chademo" ||
            filter[0] === "type_3" ||
            filter[0] === "type_2" ||
            filter[0] === "type_1"
          )
            return filter[0].toUpperCase();
        });
      if (filters.length) {
        results = results.map(item => {
          let isOk = [...item.connectors].map(connector => {
            if (filters.includes(connector.standard)) return connector;
          });
          if (isOk.filter(item => item !== undefined).length) return item;
        });
        results = results.filter(item => item !== undefined);
      }
      return results;
    }
  },
  data() {
    return {
      type: "normale"
    };
  }
};
</script>
<style lang="scss">
.search-results {
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  height: 500px;
  overflow-y: auto;
}
</style>
