<template>
  <div class="connector mb-2">
    <div class="d-flex align-center">
      <div class="connector-content">
        <div class="status d-flex align-center">
          <span
            class="dot"
            :style="{ backgroundColor: connectorStatusColor }"
          ></span>
          <div>
            <p style="font-size: .9em; margin-bottom: 0;">
              {{ connector.connector_emi3 }}
            </p>
            <span
              ><b>{{ connectorStatus }}</b></span
            >
          </div>
        </div>

        <p>Charge {{ connector.type_charge }} - {{ connector.power }} kW</p>
      </div>
      <div
        class="button"
        @click="selectConnector(connector)"
        v-if="connector.status === 'FREE' || connector.status === 'AVAILABLE'"
      >
        <v-icon class="icon">fas fa-charging-station</v-icon>Charger ici
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    connector: {
      type: Object,
      default: () => {}
    },
    poisColors: {
      type: Object,
      default: () => {}
    },
    isRoaming: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    connectorStatusColor() {
      switch (this.connector.status) {
        case "IN_USE":
          return "#1f73b4";
        case "CHARGING":
          return "#1f73b4";
        case "BLOCKED":
          return "#1f73b4";
        case "RESERVED":
          return "#1f73b4";
        case "FREE":
          return this.checkAvailableColor(this.connector);
        case "AVAILABLE":
          return this.checkAvailableColor(this.connector);
        default:
          return "";
      }
    },
    connectorStatus() {
      switch (this.connector.status) {
        case "IN_USE":
          return "En charge";
        case "CHARGING":
          return "En charge";
        case "BLOCKED":
          return "Bloquée";
        case "RESERVED":
          return "Réservée";
        case "FREE":
          return "Libre";
        case "AVAILABLE":
          return "Libre";
        default:
          return "Indisponible";
      }
    }
  },
  methods: {
    checkAvailableColor(point) {
      let availableColor = "";
      if (
        this.isRoaming &&
        (point.status === "FREE" || point.status === "AVAILABLE")
      ) {
        availableColor = this.poisColors.roaming;
      } else if (point.status === "FREE" || point.status === "AVAILABLE") {
        availableColor = this.poisColors.pois;
      }
      return availableColor;
    },
    async selectConnector(connector) {
      const connector_emi = isNaN(parseInt(connector.connector_emi3.at(-1))) ? connector.connector_emi3 : `${connector.connector_emi3}`
      window.open(`https://pay.chargedrive.com/${connector_emi}`,
        "_blank"
      );
    }
  }
};
</script>

<style></style>
