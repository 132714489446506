import * as turf from "@turf/turf";
import { GET_ONE_LOCATION, GET_STATIONS_BY_ID } from "../graphql/station";
export default {
  namespaced: true,
  state: {
    query: null,
    results: [],
    features: [],
    loading: false,
    center: null,
    selected: null
  },
  getters: {
    results(state) {
      return state.results;
    },
    query(state) {
      return state.query;
    },
    loading(state) {
      return state.loading;
    },
    features(state) {
      return state.features;
    },
    selected(state) {
      return state.selected;
    }
  },
  mutations: {
    SET_QUERY(state, value) {
      state.query = value;
    },
    SET_RESULTS(state, value) {
      state.results = value;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_FEATURES(state, value) {
      state.features = value;
    },
    SET_SELECTED(state, value) {
      state.selected = value;
    }
  },
  actions: {
    /**
     * Get nearby stations
     */
    async get({ commit }, payload) {
      commit("SET_QUERY", payload.query);
      commit("SET_LOADING", true);
      commit("SET_RESULTS", []);
      /**
       * First let's create the bounding box of the
       * area we want to search in, using turf
       *
       * We define a bounding box with a radius of 500m
       */
      let center = turf.point([payload.location.lng, payload.location.lat]);
      let buffered = turf.buffer(center, 1.5, {
        units: "kilometers"
      });
      let bbox = turf.bbox(buffered);
      const polygon = turf.bboxPolygon(bbox);
      /**
       * Then we wait for the flyTo movement to end before
       * finding features intersecting the bounding box
       * using Mapbox & turf
       *
       */
      // const markerElem = document.querySelector(".marker-map");
      // if (markerElem) markerElem.remove();

      function off() {
        payload.map.map.off("moveend", findNearby);
      }
      const findNearby = async e => {
        if (e.fromSearch) {
          /**
           * I don't know why, but passing bbox as a parameter in
           * queryRenderedFeatures doesn't work...
           */
          const features = payload.map.map.queryRenderedFeatures({
            layers: [
              "unclustered-point",
              "unclustered-roaming-alt",
              "unclustered-point-roaming"
            ]
          });
          /** Array that will contain nearby station's id */
          let nearby = [];
          let featuresInside = [];
          let locationWaiter = [];
          if (!document.querySelectorAll(".marker-map").length) {
            var el = document.createElement("div");
            el.className = "marker-map";
            new payload.map.mapbox.Marker(el)
              .setLngLat([payload.location.lng, payload.location.lat])
              .addTo(payload.map.map);
          }

          features.forEach(element => {
            const point = turf.point(element.geometry.coordinates);
            const inside = turf.booleanPointInPolygon(point, polygon);

            if (inside) {
              const request = this._vm.$apollo.query({
                query: GET_ONE_LOCATION,
                variables: {
                  id: element.properties.id
                }
              });
              locationWaiter.push(request);
              featuresInside.push(element);
            }
          });
          Promise.all(locationWaiter).then(async stations => {
            stations.map((item, i) => {
              featuresInside[i].properties.station_id = [];

              item.data.location[0].stations.map(element => {
                featuresInside[i].properties.station_id.push(element.station);
                nearby.push(element.station);
              });
            });
            /** Now let's retrieve the stations from Hasura */
            nearby = nearby.filter(item => item !== undefined);

            const {
              data: { results }
            } = await this._vm.$apollo.query({
              query: GET_STATIONS_BY_ID,
              variables: {
                nearby
              }
            });
            results.forEach(result => {
              const point = turf.point([
                result.station.longitude,
                result.station.latitude
              ]);
              result.distance = (turf.distance(center, point) * 1000).toFixed(
                0
              );
            });

            const final = [];
            results.map(result => {
              if (
                !final.find(
                  item => item.station.station_id === result.station.station_id
                )
              )
                final.push(result);
            });
            commit("SET_RESULTS", final);
            commit("SET_FEATURES", featuresInside);
            commit("SET_LOADING", false);
            off();
          });
        }
      };

      payload.map.map.on("moveend", e => setTimeout(() => findNearby(e), 1000));
    },
    async clear({ commit, state }) {
      commit("SET_QUERY", null);
      commit("SET_LOADING", true);
      commit("SET_RESULTS", []);
      if (state.marker) {
        state.marker.remove();
      }
    }
  }
};
