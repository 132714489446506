import {
  // GET_ALL_STATIONS,
  GET_ONE_STATION,
  GET_NEW_STATIONS,
  GET_RESTRICTED_STATIONS,
  GET_ONE_RESTRICTED_STATION,
  // GET_EMSP_STATIONS,
  // GET_EMSP_STATIONS_ALL,
  ADD_PROBLEME_REPORT
  // GET_EMSP_STATIONS,
  // GET_EMSP_ROAMING,
  // GET_EMSP_ALL_ROAMING
} from "@/graphql/station";
import {
  GET_CHARGING_POINTS_FROM_STATIONS,
  GET_ONE_LOCATION,
  GET_STATIONS_BY_CPO_ID
} from "../graphql/station";
// var ff = require("@mapbox/mapbox-gl-style-spec").featureFilter;
export default {
  namespaced: true,
  state: {
    stations: [],
    restrictedStations: [],
    isStationLoading: true,
    roamings: [],
    selectedEmsp: "",
    station: null,
    filters: {
      domestic: false,
      combo: false,
      chademo: false,
      type_3: false,
      type_2: false,
      type_1: false,
      normal: false,
      fast: false,
      available: false
    },
    isFilterLoading: false,
    emspNetwork: {
      network: [],
      roamings: []
    }
  },
  getters: {
    stations(state) {
      return state.stations;
    },
    restrictedStations(state) {
      return state.restrictedStations;
    },
    roamings(state) {
      return state.roamings;
    },
    station(state) {
      return state.station;
    },
    filters(state) {
      return state.filters;
    },
    selectedEmsp(state) {
      return state.selectedEmsp;
    },
    isStationLoading(state) {
      return state.isStationLoading;
    },
    emspNetwork(state) {
      return state.emspNetwork;
    }
  },
  mutations: {
    SET_ALL_STATIONS(state, stations) {
      state.stations = stations;
    },
    SET_RESTRICTED_STATIONS(state, stations) {
      state.restrictedStations = stations;
    },
    SET_ONE_STATION(state, station) {
      state.station = station;
    },
    SET_FILTERS(state, filters) {
      state.filters = { ...filters };
    },
    SET_SELECTED_EMSP(state, emsp) {
      state.selectedEmsp = emsp;
    },
    SET_LOADING(state, loading) {
      state.isStationLoading = loading;
    },
    SET_EMSP_STATIONS(state, { stations, emsp }) {
      let filterOn = [...stations];
      if (emsp) {
        let network = [];
        // if selected emsp use all stations for roaming, roaming gets all stations
        let roamings = emsp.all_roaming ? [...filterOn] : [];
        // indexes to delete from roamings
        let indexes = [];
        network = filterOn.filter((feature, index) => {
          // filters network stations when all roaming
          if (feature.properties.emsp === emsp.emsp_id && emsp.all_roaming) {
            // push index to delete
            indexes.push(index);
            return true;
            // filters network stations when not all roaming
          } else if (feature.properties.emsp === emsp.emsp_id) {
            return true;
            // when not roaming push stations that have selected emsp has roaming
          } else if (
            emsp.all_roaming === false &&
            feature.properties.emsp_roaming &&
            feature.properties.emsp_roaming.length
          ) {
            if (feature.properties.emsp_roaming.includes(emsp.emsp_id))
              roamings.push(feature);
            return false;
          }
        });
        // deletes indexes from roamings
        if (indexes.length > 0) {
          for (var i = indexes.length - 1; i >= 0; i--)
            roamings.splice(indexes[i], 1);
        }
        state.emspNetwork.emsp = emsp;
        state.emspNetwork.network = network;
        state.emspNetwork.roamings = roamings;
      }
    }
  },
  actions: {
    async getStationsByCPOsID({ commit }, cpos) {
      commit("SET_LOADING", true);

      let {
        data: { stations }
      } = await this._vm.$apollo.query({
        query: GET_STATIONS_BY_CPO_ID,
        variables: {
          cpos
        }
      });
      stations = stations.map(station => {
        return {
          geometry: station.location,
          type: "Feature",
          properties: { ...station }
        };
      });
      commit("SET_LOADING", false);

      commit("SET_ALL_STATIONS", stations);
    },

    async getNewStations({ commit }) {
      let query = {
        query: GET_NEW_STATIONS
      };
      commit("SET_LOADING", true);

      const response = await this._vm.$apollo.query(query);
      let noLocationStationIndexes = [];
      let stations = response.data.location.map((station, i) => {
        if (!station.location) return noLocationStationIndexes.push(i);
        return (station = {
          geometry: {
            type: "Point",
            coordinates: station.location?.coordinates
          },
          type: "Feature",
          properties: {
            ...station,
            status: station.status,
            emsps: station.emsps
          }
        });
      });
      if (noLocationStationIndexes.length) {
        noLocationStationIndexes.forEach(index => stations.splice(index, 1));
      }
      commit("SET_ALL_STATIONS", stations);
      commit("SET_LOADING", false);
    },

    async getOneStation({ commit }, id_station) {
      const response = await this._vm.$apollo.query({
        query: GET_ONE_STATION,
        variables: {
          id: id_station
        }
      });
      commit("SET_ONE_STATION", response.data.charging_point);
    },
    async getOnePin({ commit }, id) {
      const response = await this._vm.$apollo.query({
        query: GET_ONE_LOCATION,
        variables: {
          id // id of location item
        }
      });
      const stations = response.data.location[0].stations.map(item => {
        return item.station;
      });
      const cp_response = await this._vm.$apollo.query({
        query: GET_CHARGING_POINTS_FROM_STATIONS,
        variables: {
          stations // Array of stations' id
        }
      });
      commit("SET_ONE_STATION", cp_response.data.charging_point);
    },
    async getOneRestrictedStation({ commit }, { location_id, token }) {
      const response = await this._vm.$apollo.query({
        query: GET_ONE_RESTRICTED_STATION,
        variables: {
          location_id
        },
        context: {
          headers: {
            authorization: token // this header will reach the server,
          }
        }
      });
      const stations = response.data.location_station[0].location_data.stations.map(
        item => {
          return item.station;
        }
      );
      const cp_response = await this._vm.$apollo.query({
        query: GET_CHARGING_POINTS_FROM_STATIONS,
        variables: {
          stations // Array of stations' id
        }
      });
      commit("SET_ONE_STATION", cp_response.data.charging_point);
    },
    async getRestrictedStations({ commit }, token) {
      const response = await this._vm.$apollo.query({
        query: GET_RESTRICTED_STATIONS,
        context: {
          headers: {
            authorization: token // this header will reach the server
          }
        }
      });
      let noLocationStationIndexes = [];
      let stations = response.data.location_alt.map((station, i) => {
        if (!station.latitude || !station.longitude)
          return noLocationStationIndexes.push(i);
        return (station = {
          geometry: station.location,
          type: "Feature",
          properties: {
            ...station,
            status: station.status,
            power_type: station.charge_type,
            connector_type: station.formats,
            emsp: station.emsps
          }
        });
      });
      if (noLocationStationIndexes.length) {
        noLocationStationIndexes.forEach(index => stations.splice(index, 1));
      }
      commit("SET_RESTRICTED_STATIONS", stations);
      commit("SET_LOADING", false);
    },
    async addProblemeReport(ctx, problem_report) {
      await this._vm.$apollo.query({
        query: ADD_PROBLEME_REPORT,
        variables: {
          problem_report
        }
      });
    },
    async setselectedEmsp({ commit }, emsp) {
      commit("SET_SELECTED_EMSP", emsp);
    }
  }
};
