import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// VUETIFY
import vuetify from "@/plugins/vuetify"; // path to vuetify export

// Vee-validate
// import { ValidationProvider, ValidationObserver } from "vee-validate";
// Vue.component("ValidationProvider", ValidationProvider);
// Vue.component("ValidationObserver", ValidationObserver);
// import veevalidate rules
// import "./utils/vee-validate-rules";

// apollo client
import apollo from "@/utils/apolloClient";

import i18n from "./i18n";

// import vSelect from "vue-select";

import simplebar from "simplebar-vue";

import InlineSvg from "vue-inline-svg";
import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";
import VueQrcodeReader from "vue-qrcode-reader";

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 6,
  newestOnTop: true,
  position: "bottom-right",
  timeout: 2794,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 1.92,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  icon: true,
  rtl: false
});
Vue.use(VueQrcodeReader);
// Vue.component("v-select", vSelect);
Vue.component("simplebar", simplebar);
Vue.component("inline-svg", InlineSvg);
Vue.prototype.$apollo = apollo;

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
