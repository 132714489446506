import {
  GET_CONSULTATION_BY_ID,
  NEW_CONSULTATION_RESPONSE_FREE,
  NEW_CONSULTATION_RESPONSE_PREDEF,
  FIND_USER_IN_RESPONSES,
  INSERT_CONSULTATION,
  GET_CONSULTATION_RESPONSE_STATIONS,
  GET_CONSULTATION_LIST,
  DELETE_CONSULTATION_BY_ID,
  UPDATE_CONSULTATION_BY_ID,
  REMOVE_CONSULTATION_POINT
} from "@/graphql/consultation";

export default {
  namespaced: true,
  state: {
    consultation: null
  },
  getters: {
    // selectedEmsp(state) {
    //   return state.selectedEmsp;
    // },
    // emsps(state) {
    //   let emsps = [...state.emsps];
    //   return emsps;
    // },
    // isEmspsLoading(state) {
    //   return state.isEmspsLoading;
    // },
    // emsp(state) {
    //   let emsp = { ...state.emsp };
    //   return emsp;
    // },
    // isFiltering(state) {
    //   return state.isFiltering;
    // },
    // emspOption(state) {
    //   return state.emspOption;
    // },
    // emspColors(state) {
    //   return state.emspColors;
    // }
  },
  mutations: {
    SET_SINGLE_CONSULTATION(state, consultation) {
      state.consultation = consultation;
    }
    // SET_ALL_EMSPS(state, emsps) {
    //   state.emsps = emsps;
    // },
    // SET_ONE_EMSP(state, emsp) {
    //   state.emsp = emsp;
    // },
    // SET_FILTERING(state, filtering) {
    //   state.isFiltering = filtering;
    // },
    // SET_EMSPS_LOADING(state, isLoading) {
    //   state.isEmspsLoading = isLoading;
    // },
    // SET_EMSP_OPTION(state, options) {
    //   state.emspOption = options;
    // },
    // SET_EMSP_COLORS(state, colors) {
    //   state.emspColors = colors;
    // }
  },
  actions: {
    async getOneConsultation({ commit }, id) {
      const response = await this._vm.$apollo.query({
        query: GET_CONSULTATION_BY_ID,
        variables: {
          id: id
        }
      });
      commit("SET_SINGLE_CONSULTATION", response);
      return response.data.consultation[0];
    },
    async findUserInResponses(ctx, payload) {
      const response = await this._vm.$apollo.query({
        query: FIND_USER_IN_RESPONSES,
        variables: {
          id: payload.id,
          mail: payload.mail
        }
      });
      // commit("SET_SINGLE_CONSULTATION", response);
      return response.data.consultation_response;
    },
    async newResponseFree(ctx, data) {
      const response = await this._vm.$apollo.query({
        query: NEW_CONSULTATION_RESPONSE_FREE,
        variables: {
          object: {
            address: data.address,
            company: data.company,
            email: data.email,
            firstname: data.firstname,
            has_electric_car_ordered: data.hasElectricCarOrdered,
            has_electric_car_buyed: data.hasElectricCarBuyed,
            has_no_parking: data.hasNoParking,
            has_no_recharge_point: data.hasNoRechargePoint,
            identity: data.identity,
            lastname: data.lastname,
            location: data.location,
            location_complement: data.location_complement,
            phone: data.phone,
            consultation_station: {
              data: {
                data: data.poi.data,
                is_free_choice: data.poi.is_free_choice,
                id_consultation: data.id_consultation
              }
            },
            id_consultation: data.id_consultation,

            using_time: data.usage_time
          }
        }
      });
      return response;
    },
    async newResponsePredef(ctx, data) {
      const response = await this._vm.$apollo.query({
        query: NEW_CONSULTATION_RESPONSE_PREDEF,
        variables: {
          object: {
            address: data.address,
            company: data.company,
            custom_address: data.custom_address,
            email: data.email,
            firstname: data.firstname,
            has_electric_car_ordered: data.hasElectricCarOrdered,
            has_electric_car_buyed: data.hasElectricCarBuyed,
            has_no_parking: data.hasNoParking,
            has_no_recharge_point: data.hasNoRechargePoint,
            identity: data.identity,
            lastname: data.lastname,
            location: data.location,
            location_complement: data.location_complement,
            phone: data.phone,
            id_consultation: data.id_consultation,
            id_station: data.poi.id,
            using_time: data.usage_time
          }
        }
      });
      return response;
    },
    async insertConsultation(ctx, consultation) {
      const response = await this._vm.$apollo.mutate({
        mutation: INSERT_CONSULTATION,
        variables: {
          consultation
        }
      });
      return response.data;
    },
    async getConsultationResponseStations(ctx, id_consultation) {
      const response = await this._vm.$apollo.mutate({
        mutation: GET_CONSULTATION_RESPONSE_STATIONS,
        variables: {
          id_consultation
        }
      });
      return response.data.consultation_station;
    },
    async getConsultationList() {
      const response = await this._vm.$apollo.query({
        query: GET_CONSULTATION_LIST
      });
      return response.data.consultation;
    },
    async deleteConsultation(ctx, consultation_id) {
      await this._vm.$apollo.mutate({
        mutation: DELETE_CONSULTATION_BY_ID,
        variables: {
          consultation_id
        }
      });
    },
    async updateConsultation(ctx, { consultation, points }) {
      await this._vm.$apollo.mutate({
        mutation: UPDATE_CONSULTATION_BY_ID,
        variables: {
          consultation,
          points
        }
      });
    },
    async removeConsultationPoint(ctx, { point, consultation_id }) {
      await this._vm.$apollo.mutate({
        mutation: REMOVE_CONSULTATION_POINT,
        variables: {
          consultation_id,
          point_id: point.id
        }
      });
    }
  }
};
