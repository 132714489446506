export default {
  data: () => ({
    filterValues: {
      domestic: ["SCHUKO", "DOMESTIC_F"],
      type_1: ["TYPE_1_ATCH"],
      type_2: ["TYPE_2", "TYPE_2_ATCH", "IEC_62196_T2"],
      type_3: "TYPE_3",
      combo: ["CCS", "IEC_62196_T2_COMBO"],
      chademo: "CHADEMO",
      normal: "normale",
      fast: "rapide",
      available: ["FREE", "AVAILABLE"]
    }
  }),
  computed: {
    // selectedEmsp() {
    //   return this.$store.getters["station/selectedEmsp"];
    // },
  },
  methods: {
    // prefix every property of the myxin by $_ to avoid properties with the same name from being overwritted
    $_filter: function({
      filters,
      selectedEmsp,
      map,
      network = [],
      roamings = []
    }) {
      let toFilter = [];
      let filterAnd = [];
      let filterCopy = { ...filters };
      // push values to filer on
      if (filterCopy.fast) {
        filterAnd.push("rapide");
        delete filterCopy.fast;
      }
      if (filterCopy.normal) {
        filterAnd.push("normale");
        delete filterCopy.normal;
      }
      if (filterCopy.available) {
        filterAnd.push(["FREE", "AVAILABLE"]);
        delete filterCopy.available;
      }
      for (let filter in this.filterValues) {
        if (filterCopy[filter]) {
          if (Array.isArray(this.filterValues[filter]))
            toFilter.push(...this.filterValues[filter]);
          else toFilter.push(this.filterValues[filter]);
        }
      }

      let doFilter = points => {
        return points.filter(point => {
          let isFiltered = false;
          // concat station properties to filter

          let dataPoint = [
            point.properties.status,
            point.properties.charge_type,
            ...point.properties.formats
          ];
          if (toFilter.length && filterAnd.length) {
            toFilter.forEach(filter => {
              if (dataPoint.includes(filter)) isFiltered = true;
            });
            filterAnd.forEach(filter => {
              if (Array.isArray(filter)) {
                let [filter1, filter2] = filter;
                if (
                  !dataPoint.includes(filter1) &&
                  !dataPoint.includes(filter2)
                )
                  isFiltered = false;
              } else if (!dataPoint.includes(filter)) isFiltered = false;
            });
          } else if (toFilter.length) {
            toFilter.forEach(filter => {
              if (dataPoint.includes(filter)) isFiltered = true;
            });
          } else if (filterAnd.length) {
            isFiltered = true;
            filterAnd.forEach(filter => {
              if (Array.isArray(filter)) {
                let [filter1, filter2] = filter;
                if (
                  !dataPoint.includes(filter1) &&
                  !dataPoint.includes(filter2)
                )
                  isFiltered = false;
              } else if (!dataPoint.includes(filter)) isFiltered = false;
            });
          }
          return isFiltered;
        });
      };

      // if no filter set initial data layer
      if (!toFilter.length && !filterAnd.length) {
        map.map
          .getSource("roaming")
          .setData({ type: "FeatureCollection", features: network });
        map.map
          .getSource("roaming-alt")
          .setData({ type: "FeatureCollection", features: network });
        map.map.getSource("stations").setData({
          type: "FeatureCollection",
          features: network.length ? roamings : [...this.stations]
        });
        map.map.getSource("alt").setData({
          type: "FeatureCollection",
          features: network.length ? roamings : [...this.stations]
        });
        return;
      }

      let filteredRoaming = doFilter(
        selectedEmsp ? [...roamings] : [...this.stations]
      );
      let filteredNetwork = doFilter([...network]);

      map.map.getSource("roaming").setData({
        type: "FeatureCollection",
        features: network.length ? filteredNetwork : []
      });
      map.map.getSource("roaming-alt").setData({
        type: "FeatureCollection",
        features: network.length ? filteredNetwork : []
      });

      map.map
        .getSource("alt")
        .setData({ type: "FeatureCollection", features: filteredRoaming });
      map.map
        .getSource("stations")
        .setData({ type: "FeatureCollection", features: filteredRoaming });
    },
    $_filter_restricted: function({
      filters,
      selectedEmsp,
      map,
      network = [],
      roamings = []
    }) {
      let toFilter = [];
      let filterAnd = [];
      let filterCopy = { ...filters };
      // push values to filer on
      if (filterCopy.fast) {
        filterAnd.push("rapide");
        delete filterCopy.fast;
      }
      if (filterCopy.normal) {
        filterAnd.push("normal");
        delete filterCopy.normal;
      }
      if (filterCopy.available) {
        filterAnd.push(["FREE", "AVAILABLE"]);
        delete filterCopy.available;
      }
      for (let filter in this.filterValues) {
        if (filterCopy[filter]) {
          if (Array.isArray(this.filterValues[filter]))
            toFilter.push(...this.filterValues[filter]);
          else toFilter.push(this.filterValues[filter]);
        }
      }

      let doFilter = points => {
        return points.filter(point => {
          let isFiltered = false;
          // concat station properties to filter
          let dataPoint = [
            point.properties.status,
            point.properties.charge_type,
            ...point.properties.connector_type
          ];
          if (toFilter.length && filterAnd.length) {
            toFilter.forEach(filter => {
              if (dataPoint.includes(filter)) isFiltered = true;
            });
            filterAnd.forEach(filter => {
              if (Array.isArray(filter)) {
                let [filter1, filter2] = filter;
                if (
                  !dataPoint.includes(filter1) &&
                  !dataPoint.includes(filter2)
                )
                  isFiltered = false;
              } else if (!dataPoint.includes(filter)) isFiltered = false;
            });
          } else if (toFilter.length) {
            toFilter.forEach(filter => {
              if (dataPoint.includes(filter)) isFiltered = true;
            });
          } else if (filterAnd.length) {
            isFiltered = true;
            filterAnd.forEach(filter => {
              if (Array.isArray(filter)) {
                let [filter1, filter2] = filter;
                if (
                  !dataPoint.includes(filter1) &&
                  !dataPoint.includes(filter2)
                )
                  isFiltered = false;
              } else if (!dataPoint.includes(filter)) isFiltered = false;
            });
          }
          return isFiltered;
        });
      };

      // if no filter set initial data layer
      if (!toFilter.length && !filterAnd.length) {
        map.map
          .getSource("roaming")
          .setData({ type: "FeatureCollection", features: network });
        map.map
          .getSource("roaming-alt")
          .setData({ type: "FeatureCollection", features: network });
        map.map.getSource("stations").setData({
          type: "FeatureCollection",
          features: network.length ? roamings : [...this.stations]
        });
        map.map.getSource("alt").setData({
          type: "FeatureCollection",
          features: network.length ? roamings : [...this.stations]
        });
        return;
      }

      let filteredRoaming = doFilter(
        selectedEmsp ? [...roamings] : [...this.stations]
      );
      let filteredNetwork = doFilter([...network]);

      map.map.getSource("roaming").setData({
        type: "FeatureCollection",
        features: network.length ? filteredNetwork : []
      });
      map.map.getSource("roaming-alt").setData({
        type: "FeatureCollection",
        features: network.length ? filteredNetwork : []
      });

      map.map
        .getSource("alt")
        .setData({ type: "FeatureCollection", features: filteredRoaming });
      map.map
        .getSource("stations")
        .setData({ type: "FeatureCollection", features: filteredRoaming });
    }
  }
};
