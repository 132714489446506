export default {
  methods: {
    // prefix every property of the myxin by $_ to avoid properties with the same name from being overwritted
    $_redirectTo: function(url, langFull) {
      let lang = langFull.split("-")[0];
      if (url.name) {
        this.$router.push({ name: url.name, params: { lang } });
      } else {
        window.location = url + langFull;
      }
    }
  }
};
