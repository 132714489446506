<template
  ><section class="overlay">
    <section class="panels-popup d-flex justify-space-between">
      <section class="left">
        <div class="search d-flex mb-4">
          <div
            v-if="!arePanelsOpen"
            @click="arePanelsOpen = true"
            class="burger d-flex justify-center align-center"
          >
            <img src="@/assets/map/ic_burger.svg" alt="icone menu burger" />
          </div>
          <div
            v-if="arePanelsOpen"
            @click="arePanelsOpen = false"
            class="burger d-flex justify-center align-center"
          >
            <img src="@/assets/map/ic_close_grey.svg" alt="icone menu burger" />
          </div>
          <Search
            @search="panels = 3"
            @clear="handleClearSearch"
            :map="$parent.$refs[parentMapStr]"
          ></Search>
        </div>
        <v-expand-transition>
          <section v-show="arePanelsOpen" class="panels">
            <v-expansion-panels accordion flat :value="panels">
              <v-expansion-panel v-if="selectedEmsp && parentMapStr !== 'map'">
                <v-expansion-panel-header>
                  <div class="filter d-flex align-center">
                    <img :src="selectedEmsp.params.logo" alt="" />
                    <strong>{{ selectedEmsp.name }}</strong>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="main-panel">
                  <section class="emsp-content pa-2 pt-0">
                    <div
                      v-if="emspInfos.price_description"
                      v-html="emspInfos.price_description"
                      class="emsp-text"
                    ></div>

                    <!-- <p class="partenaire">
                      <a :href="selectedEmsp.page">
                        <v-icon :style="{ color: poisColors.roaming }"
                          >fas fa-info-circle</v-icon
                        >
                        <span
                          class="ml-1"
                          :style="{ color: poisColors.roaming }"
                          >{{ $t("emsp.details") }}</span
                        >
                      </a>
                    </p> -->
                    <p>
                      <v-btn
                        :href="selectedEmsp.signup"
                        :style="{
                          background: poisColors.roaming,
                          color: poisColors.text + '!important'
                        }"
                        dark
                        small
                        tile
                        class="mr-4"
                        target="_blank"
                        v-if="selectedEmsp.signup"
                        >{{ $t("emsp.register") }}</v-btn
                      >
                      <v-btn
                        :href="selectedEmsp.page"
                        :style="{
                          background: poisColors.roaming,
                          color: poisColors.text + '!important'
                        }"
                        dark
                        small
                        tile
                        class="mr-4"
                        target="_blank"
                        >{{ $t("emsp.details") }}</v-btn
                      >
                    </p>
                  </section>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="filter d-flex align-center">
                    <inline-svg
                      :src="require('../../assets/map/ic_filters.svg')"
                      :fill="
                        parentMapStr === 'map'
                          ? poisColors.pois
                          : poisColors.roaming
                      "
                    ></inline-svg>
                    {{ $t("overlay.filters") }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="main-panel" eager>
                  <simplebar
                    class="filter-panel"
                    data-simplebar-auto-hide="false"
                  >
                    <FilterPanel :isShowing="true"></FilterPanel>
                  </simplebar>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="emsps.length && !hideEmsps && !isRestrictedMap"
              >
                <v-expansion-panel-header>
                  <div class="filter d-flex align-center">
                    <inline-svg
                      :src="require('../../assets/map/ic_reseau.svg')"
                      :fill="
                        parentMapStr === 'map'
                          ? poisColors.pois
                          : poisColors.roaming
                      "
                    ></inline-svg>
                    {{ $t("overlay.networks") }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="main-panel">
                  <simplebar
                    class="emsp-panel"
                    data-simplebar-auto-hide="false"
                  >
                    <EMSPS :emsps="emsps"></EMSPS>
                  </simplebar>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-show="query">
                <v-expansion-panel-header>
                  <div class="filter d-flex align-center">
                    <inline-svg
                      :src="require('../../assets/map/pin.svg')"
                      :fill="
                        parentMapStr === 'map'
                          ? poisColors.pois
                          : poisColors.roaming
                      "
                    ></inline-svg>
                    <span v-if="query === ' '"> {{ $t("map.nearby-me") }}</span>
                    <span v-else>
                      {{ $t("map.nearby") }} <b>{{ query }}</b></span
                    >
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <SearchResults />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <div
                v-if="false"
                style="background-color: #fff; width: 100%; padding: .75em 1em;"
                class="filter"
              >
                <div class="return d-flex align-center" @click="mapReturn">
                  <inline-svg
                    :src="require('../../assets/map/ic_home.svg')"
                    :fill="
                      parentMapStr === 'map'
                        ? poisColors.pois
                        : poisColors.roaming
                    "
                  ></inline-svg>
                  <span>{{ $t("map.return") }}</span>
                </div>
              </div>
            </v-expansion-panels>
          </section>
        </v-expand-transition>
        <AroundMe :map="mapObject" @search="panels = 3" />
      </section>
    </section>
  </section>
</template>

<script>
import FilterPanel from "@/components/map/filter/Filters";
import EMSPS from "@/components/map/filter/EMSPS";
import Search from "@/components/map/filter/Search";
import LangSwitch from "@/components/LangSwitch";
import redirectTo from "@/mixins/redirectTo";
import SearchResults from "@/components/map/filter/SearchResults";
import AroundMe from "@/components/map/filter/AroundMe";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    isPopupOpen: {
      type: Boolean,
      default: false
    },
    redirect: {
      type: String,
      default: "https://alizecharge.com"
    },
    emsps: {
      type: Array,
      default: () => []
    },
    register: {
      type: Boolean,
      default: false
    },
    isRestrictedMap: {
      type: Boolean,
      default: false
    },
    parentMapStr: {
      type: String,
      default: ""
    },
    emspOption: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // selectedEmsp: {
    //   type: Object,
    //   default: () => ({})
    // },
    poisColors: {
      type: Object,
      default: () => {
        return { roaming: "#fff200", pois: "#96C01F", text: "#232323" };
      }
    },
    mapObject: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    FilterPanel,
    Search,
    LangSwitch,
    EMSPS,
    SearchResults,
    AroundMe
  },
  mixins: [redirectTo],
  data: () => ({
    arePanelsOpen: true,
    panels: 0,
    hideEmsps: false
  }),
  created() {
    if (window.innerWidth < 500) this.arePanelsOpen = false;
    if (this.$route.params.id) this.hideEmsps = true;
  },
  mounted() {
    this.panels = this.register ? 2 : 0;
  },
  computed: {
    ...mapGetters("search", ["query"]),
    supportUrl() {
      return (
        (this.selectedEmsp && this.selectedEmsp.support) ||
        "http://support.alizecharge.com/support/home"
      );
    },
    selectedEmsp() {
      return this.$store.getters["station/selectedEmsp"];
    }
  },
  methods: {
    ...mapActions("search", ["clear"]),
    handleClearSearch() {
      this.panels = 0;
      this.clear();
    },
    mapReturn() {
      if (this.redirect)
        this.$_redirectTo(
          this.redirect,
          this.$root.$i18n.locale !== "fr" ? this.$root.$i18n.locale : ""
        );
      else {
        this.$store.commit("station/SET_SELECTED_EMSP", null);
        this.$router.push("/");
      }
      this.hideEmsps = false;
    }
  }
};
</script>

<style lang="scss">
.overlay {
  color: #404040;
  width: 100%;
  height: 80vh;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2em;
  .left {
    width: 25%;
    min-width: 320px;
    .search {
      position: relative;
      height: 39px;
      width: 100%;
      background: white;
      border-radius: 6px;
      box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.33);
      z-index: 2;
      .burger {
        height: 100%;
        padding-left: 1em;
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
    .filter {
      svg,
      img {
        margin-right: 0.5em;
        height: 20px;
      }
      svg {
        width: 20px;
      }
    }
  }

  .panels {
    position: relative;
    z-index: 2;
    scrollbar-width: none;
    scrollbar-color: light;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.33);
    border-radius: 6px;
    a {
      text-decoration: none;
    }
    .emsp-panel {
      height: 30vh;
      overflow: auto;
    }
    .filter-panel {
      height: 30vh;
      overflow: auto;
    }
    .emsp-content {
      p {
        padding: 0 0.6em;
        p {
          padding: 0;
          margin-top: 1em;
        }
      }
      i + p {
        display: inline;
        margin-left: 1em;
      }
    }
    .legends {
      display: flex;
      flex-wrap: wrap;

      .legend {
        font-size: 0.9em;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 0.5em;
        &.green-pin {
          img {
            width: 20px;
            color: red;
            background: #000;
          }
        }
      }
    }
  }
  .v-expansion-panel-header {
    padding: 0.85em 1em;
    border-bottom: 1px solid #eee;
    &.v-expansion-panel-header--active {
      min-height: 48px;
    }
  }
  .v-expansion-panels {
    border-radius: 6px;
  }
  .v-expansion-panel-content {
    & > .v-expansion-panel-content__wrap {
      padding-top: 0.5em !important;
    }
    border-bottom: 1px solid #eee;
  }
  .v-expansion-panel {
    &:last-child {
      .v-expansion-panel-header:not(.v-expansion-panel-header--active),
      .v-expansion-panel-content {
        border-bottom: 0;
      }
    }
  }

  .langswitch {
    width: 100%;
    height: 60px;
    img {
      margin-right: 0.25em;
    }
  }

  .v-icon.v-icon {
    font-size: 15px;
  }
}

.dot {
  padding: 0.6em;
  font-size: 0.85em;
  width: 100%;
  span {
    background: #ccc;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 1em;
  }
}
.green-dot {
  span {
    background: #59b570;
  }
}
.blue-dot {
  span {
    background: #1f73b4;
  }
}
.yellow-dot {
  span {
    background: #fff200;
  }
}

@media screen and (max-width: 420px) {
  .overlay {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    .site-header {
      margin-top: 1em;
      padding-left: 2em;
    }
    .left {
      width: 100%;
      margin-top: 0;
      margin-left: 0;
    }
  }
}
</style>
