import { GET_ALL_EMSPS, GET_ONE_EMSP } from "@/graphql/emsp";
import axios from "axios";
export default {
  namespaced: true,
  state: {
    selectedEmsp: null,
    emsps: [],
    emsp: {},
    isFiltering: false,
    isEmspsLoading: false,
    emspOption: [],
    emspColors: {
      roaming: "#fff200",
      pois: "#59b570",
      text: "#141417"
    }
  },
  getters: {
    selectedEmsp(state) {
      return state.selectedEmsp;
    },
    emsps(state) {
      let emsps = [...state.emsps];
      return emsps;
    },
    isEmspsLoading(state) {
      return state.isEmspsLoading;
    },
    emsp(state) {
      let emsp = { ...state.emsp };
      return emsp;
    },
    isFiltering(state) {
      return state.isFiltering;
    },
    emspOption(state) {
      return state.emspOption;
    },
    emspColors(state) {
      return state.emspColors;
    }
  },
  mutations: {
    SET_EMSP(state, emsp) {
      state.selectedEmsp = emsp;
    },
    SET_ALL_EMSPS(state, emsps) {
      state.emsps = emsps;
    },
    SET_ONE_EMSP(state, emsp) {
      state.emsp = emsp;
    },
    SET_FILTERING(state, filtering) {
      state.isFiltering = filtering;
    },
    SET_EMSPS_LOADING(state, isLoading) {
      state.isEmspsLoading = isLoading;
    },
    SET_EMSP_OPTION(state, options) {
      state.emspOption = options;
    },
    SET_EMSP_COLORS(state, colors) {
      state.emspColors = colors;
    }
  },
  actions: {
    async getAllEMSPs({ commit }) {
      commit("SET_EMSPS_LOADING", true);
      const response = await this._vm.$apollo.query({
        query: GET_ALL_EMSPS
      });
      let emsps = response.data.emsp;
      commit("SET_ALL_EMSPS", emsps);
      commit("SET_EMSPS_LOADING", false);
    },
    async getOneEmsp(ctx, emsp) {
      const response = await this._vm.$apollo.query({
        query: GET_ONE_EMSP,
        variables: {
          id: emsp
        }
      });
      return response.data.emsp[0];
    },
    async getEmspOption({ commit }) {
      let response = await axios.get(
        "https://alizecharge.com/fr/wp-json/acf/v3/abonnement?per_page=20"
      );
      commit("SET_EMSP_OPTION", response.data);
      return response.data;
    },
    async getEmspPoisColor() {
      // let url = page + "wp-json/acf/v3/options/options";
      // let response = await axios.get(url);
      // let colors = {
      //   pois: response.data.acf.couleur_pois
      //     ? response.data.acf.couleur_pois
      //     : state.emspColors.pois,
      //   roaming: response.data.acf.couleur_pois_roaming
      //     ? response.data.acf.couleur_pois_roaming
      //     : state.emspColors.roaming,
      //   text: response.data.acf.couleur_texte_clusters
      //     ? response.data.acf.couleur_texte_clusters
      //     : state.emspColors.text
      // };
      // commit("SET_EMSP_COLORS", colors);
    },
    async resetEmspColors({ commit }) {
      let colors = {
        roaming: "#fff200",
        pois: "#59b570",
        text: "#141417"
      };
      commit("SET_EMSP_COLORS", colors);
    }
  }
};
