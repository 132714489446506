import gql from "graphql-tag";

// exemple of graphql query
const GET_ALL_STATIONS = gql`
  query getAllStations {
    station {
      # city
      country
      connector_type {
        connector_types
      }
      location
      status {
        status
      }
      power_type {
        power_types
      }
      emsp(where: { roaming: { _eq: false } }) {
        emsp_id
      }
      roaming: emsp(where: { roaming: { _eq: true } }) {
        emsp_id
      }
      station {
        cpo_id
      }
      cpo {
        cpo_id
        name
      }
      # emsp_id
      # name
      station_id
      street_name
      zip_code
    }
  }
`;

const GET_ONE_STATION = gql`
  query getOneStation($id: [String!]!) {
    charging_point(
      where: {
        _and: [
          { soft_delete: { _eq: false } }
          { station: { station_id: { _in: $id } } }
        ]
      }
    ) {
      emi3
      type_charge
      connectors {
        standard
        connector_id
        format
        amperage
        voltage
        power
        power_type
        connector_emi3
      }
      power
      power_type
      status
      station {
        station_id
        country
        street_name
        street_number
        cpo_name
        latitude
        longitude
        zip_code
        cpo_id
        town
        cpo {
          name
        }
      }
    }
  }
`;

// New request for oction_alt implementation
const GET_ONE_LOCATION = gql`
  query getOneLocation($id: String!) {
    location: location_alt(where: { id: { _eq: $id } }) {
      stations {
        station
      }
    }
  }
`;

const GET_CHARGING_POINTS_FROM_STATIONS = gql`
  query getOneStation($stations: [String!]!) {
    charging_point(
      where: {
        _and: [
          { soft_delete: { _eq: false } }
          { station: { station_id: { _in: $stations } } }
        ]
      }
    ) {
      emi3
      type_charge
      connectors {
        standard
        connector_id
        format
        amperage
        voltage
        power
        power_type
        connector_emi3
        connector_standard {
          name
          type
          icon
        }
      }
      power
      power_type
      status
      station {
        station_id
        country
        street_name
        street_number
        cpo_name
        latitude
        longitude
        zip_code
        cpo_id
        town
        cpo {
          name
        }
      }
    }
  }
`;

const GET_NEW_STATIONS = gql`
  query getNewStations {
    location: location_alt(where: { is_public: { _eq: true } }) {
      id
      emsps
      status
      location
      charge_type
      formats
    }
  }
`;
export const GET_STATIONS_BY_CPO_ID = gql`
  query MyQuery($cpos: [String!]!) {
    stations: station(where: { cpo_id: { _in: $cpos } }) {
      activation_date
      code_insee
      country
      cpo_id
      cpo_name
      create_at
      external_id
      is_public
      last_update
      latitude
      location
      longitude
      max_power
      remove_date
      soft_delete
      state
      station_emi3
      station_name
      street_name
      street_number
      supervision_id
      town
      update_at
      update_by
      zip_code
    }
  }
`;
const GET_EMSP_STATIONS_ALL = gql`
  query getNewStations($id: String!) {
    location(
      where: { _or: [{ is_public: { _eq: true } }, { emsp: { _eq: $id } }] }
    ) {
      emsp
      format
      location
      charge
      station_id
      status
      emsp_roaming
    }
  }
`;
const GET_EMSP_STATIONS = gql`
  query getNewStations($emsp_id: jsonb!, $id: String!) {
    location(
      where: {
        _or: [{ emsp_roaming: { _contains: $emsp_id } }, { emsp: { _eq: $id } }]
      }
    ) {
      emsp
      format
      location
      charge
      station_id
      status
      emsp_roaming
    }
  }
`;
const GET_RESTRICTED_STATIONS = gql`
  query getRestrictedStations {
    location_alt(
      where: {
        stations: { station_data: { station_name: { _is_null: false } } }
      }
    ) {
      id
      charge_type
      cpo
      formats
      emsps
      is_public
      latitude
      location
      longitude
      status
    }
  }
`;
const ADD_PROBLEME_REPORT = gql`
  mutation insertProblemReport(
    $problem_report: [problem_reports_insert_input!]!
  ) {
    insert_problem_reports(objects: $problem_report) {
      affected_rows
    }
  }
`;

const GET_ONE_RESTRICTED_STATION = gql`
  query getOneRestrictedStation($location_id: String!) {
    location_station(where: { location: { _eq: $location_id } }) {
      location_data {
        stations {
          station
        }
      }
      station_data {
        cpo_name
        cpo_id
        station_id
        country
        street_name
        street_number
        cpo_name
        zip_code
        cpo_id
        town

        cpo {
          name
        }
        charging_points {
          emi3
          type_charge
          connectors {
            standard
            connector_id
            format
            amperage
            voltage
            power
            power_type
            connector_emi3
            connector_standard {
              name
              type
              icon
            }
          }
          power
          power_type
          status
        }
      }
    }
  }
`;
const GET_STATIONS_BY_ID = gql`
  query getNearbyCP($nearby: [String!]) {
    results: charging_point(
      where: { station: { station_id: { _in: $nearby } } }
    ) {
      type_charge
      status
      power_type
      power
      emi3
      connectors {
        standard
      }
      station {
        latitude
        longitude
        station_name
        street_name
        street_number
        town
        zip_code
        station_id
        emsp {
          emsp {
            name
            params
          }
        }
        cpo {
          cpo_id
          name
          is_public
          tariffs {
            emsp {
              offer_type
            }
          }
        }
      }
    }
  }
`;

export {
  GET_ALL_STATIONS,
  GET_ONE_STATION,
  GET_NEW_STATIONS,
  GET_EMSP_STATIONS,
  GET_EMSP_STATIONS_ALL,
  ADD_PROBLEME_REPORT,
  GET_RESTRICTED_STATIONS,
  GET_ONE_RESTRICTED_STATION,
  GET_STATIONS_BY_ID,
  GET_ONE_LOCATION,
  GET_CHARGING_POINTS_FROM_STATIONS
};
