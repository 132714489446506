<template>
  <div v-if="item" @click="openPopup" class="search-item" elevation="0">
    <div class="cpo-type">
      <v-icon large
        >{{
          data.station.cpo.tariffs[0] &&
          this.data.station.cpo.tariffs[0].emsp &&
          this.data.station.cpo.tariffs[0].emsp.offer_type === "parking"
            ? "fas fa-parking"
            : "fas fa-charging-station"
        }}
      </v-icon>
    </div>
    <div class="search-item-content">
      <h4>
        <img
          class="emsp-logo"
          v-if="item.station.emsp.length"
          :src="item.station.emsp[0].emsp.params.logo"
          :alt="item.station.emsp[0].emsp.name"
        />
        <span v-else
          >{{
            item.station.cpo.name ? item.station.cpo.name.toUpperCase() : null
          }}
        </span>
        <br v-if="item.station.station_name" />

        {{ item.station.station_name }}
      </h4>
      <p class="search-item-location">
        {{ item.station.street_number }} {{ item.station.street_name }}
        {{ item.station.zip_code }}
        {{ item.station.town }}
      </p>
      <div class="result-connectors-list">
        <span
          v-for="(connector, i) in item.connectors"
          :key="i"
          v-html="connector.icon"
          class="plug-format"
        ></span>
      </div>
      <span class="power">Puissance : {{ item.power }} kW </span>
    </div>
    <div class="distance-indicator">
      <span class="value">
        {{
          item.distance > 1000
            ? (item.distance / 1000).toPrecision(2)
            : item.distance
        }}
      </span>
      <span class="unit">{{ item.distance > 1000 ? "km" : "mètres" }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import mapboxgl from "mapbox-gl";
import EventBus from "@/utils/eventBus.js";

export default {
  props: ["data"],
  data() {
    return {
      item: null
    };
  },
  mounted() {
    const item = { ...this.data };
    let connectors = [];
    item.connectors.map(function(connector) {
      switch (connector.standard) {
        case "SCHUKO":
          connector.icon = "&#xe80d;";
          break;
        case "DOMESTIC_A":
          connector.icon = "&#xe80d;";
          break;
        case "DOMESTIC_B":
          connector.icon = "&#xe80d;";
          break;
        case "DOMESTIC_C":
          connector.icon = "&#xe80d;";
          break;
        case "DOMESTIC_D":
          connector.icon = "&#xe80d;";
          break;
        case "DOMESTIC_E":
          connector.icon = "&#xe80d;";
          break;
        case "DOMESTIC_F":
          connector.icon = "&#xe80d;";
          break;
        case "DOMESTIC_G":
          connector.icon = "&#xe80d;";
          break;
        case "DOMESTIC_H":
          connector.icon = "&#xe80d;";
          break;
        case "DOMESTIC_I":
          connector.icon = "&#xe80d;";
          break;
        case "DOMESTIC_J":
          connector.icon = "&#xe80d;";
          break;
        case "DOMESTIC_K":
          connector.icon = "&#xe80d;";
          break;
        case "DOMESTIC_L":
          connector.icon = "&#xe80d;";
          break;
        case "TYPE_1":
          connector.icon = "&#xe80e;";
          break;
        case "TYPE_1_ATCH":
          connector.icon = "&#xe80e;";
          break;
        case "IEC_62196_T1":
          connector.icon = "&#xe80e;";
          break;
        case "IEC_62196_T2":
          connector.icon = "&#xe80f;";
          break;
        case "TYPE_2":
          connector.icon = "&#xe80f;";
          break;
        case "TYPE_2_ATCH":
          connector.icon = "&#xe80f;";
          break;
        case "TYPE_3":
          connector.icon = "&#xe810;";
          break;
        case "IEC_62196_T3A":
          connector.icon = "&#xe810;";
          break;
        case "IEC_62196_T3C":
          connector.icon = "&#xe810;";
          break;
        case "CHADEMO":
          connector.icon = "&#xe803;";
          break;
        case "CCS":
          connector.icon = "&#xe80c;";
          break;
        case "IEC_62196_T2_COMBO":
          connector.icon = "&#xe80c;";
          break;
        case "IEC_62196_T1_COMBO":
          connector.icon = "&#xe80c;";
          break;
        default:
          break;
      }

      if (!connectors.find(element => element.standard === connector.standard))
        connectors.push(connector);
    });
    this.item = { ...item, connectors };
  },
  computed: {
    ...mapGetters("search", ["features"])
  },
  methods: {
    openPopup() {
      const feature = this.features.find(item =>
        item.properties.station_id.includes(this.data.station.station_id)
      );
      let payload = {
        feature,
        point: new mapboxgl.LngLat(
          this.data.station.longitude,
          this.data.station.latitude
        )
      };
      EventBus.$emit("click-item", payload);
    }
  }
};
</script>
<style lang="scss">
.search-item {
  .emsp-logo {
    width: 100px;
  }
  .power {
    font-size: 0.9rem;
  }
  display: flex !important;
  align-items: center;
  justify-content: space-evenly;
  min-height: 200px;
  .search-item-content {
    width: 200px;
  }
  .result-connectors-list {
    display: flex;
    .plug-format {
      font-family: "SCHUKO";
      font-size: 1.25em;
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
  .search-item-location {
    margin-bottom: 0;
    font-size: 0.7rem;
  }

  .cpo-type {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 2px !important;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain;
    background-position: center;
  }
  .distance-indicator {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 2px !important;

    background-color: white;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    background-size: contain;
    background-position: center;
    .value {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    .unit {
      font-size: 0.8rem;
    }
  }
  .v-card__title {
    font-size: 0.9rem;
    line-height: 1.2rem;
    word-break: keep-all;
  }
  .v-card__subtitle {
    font-size: 0.8rem;
    line-height: 1rem;
    word-break: keep-all;
  }
}
</style>
