import { GET_PASSWORD_FROM_USER_TOKEN } from "../graphql/map";
export default {
  namespaced: true,
  state: {
    password: null
  },
  getters: {
    password(state) {
      return state.password;
    }
  },
  mutations: {
    SET_PASSWORD(state, val) {
      state.password = val;
    }
  },
  actions: {
    async getPassword({ commit }, token) {
      const { data } = await this._vm.$apollo.query({
        query: GET_PASSWORD_FROM_USER_TOKEN,
        variables: {
          token
        }
      });
      commit(
        "SET_PASSWORD",
        data.user_token[0]?.password ? data.user_token[0]?.password : null
      );
    }
  }
};
