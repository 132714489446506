<template>
  <v-row class="locale-changer">
    <v-col
      cols="4"
      class="lang d-flex justify-center align-center pa-0 pa-2"
      :class="{ selected: lang.lang === $root.$i18n.locale }"
      v-for="lang in langs"
      :key="lang.label"
      @click="changeSiteLang(lang.lang)"
    >
      <img :src="lang.flag" />
      <span class="pl-1">{{ lang.label }}</span>
    </v-col>
  </v-row>
</template>

<script>
import frenchFlag from "@/assets/map/flag-fr.svg";
import englishFlag from "@/assets/map/flag-en.svg";
import deutschFlag from "@/assets/map/flag-de.svg";
export default {
  name: "locale-changer",
  data() {
    return {
      langs: [
        { label: "Français", flag: frenchFlag, lang: "fr" },
        { label: "English", flag: englishFlag, lang: "en" },
        { label: "Deutsch", flag: deutschFlag, lang: "de" }
      ]
    };
  },
  methods: {
    // TODO : add function to add lang in the url for global lang
    changeSiteLang(lang) {
      this.$root.$i18n.locale = lang;
      // let lang = this.$root.$i18n.locale.split("-")[0];
      // let routeName = this.$route.name;
      // this.$router.push({ params: { lang }, name: routeName });
    }
  }
};
</script>

<style lang="scss">
.locale-changer {
  align-items: center;
  width: 100%;
  height: 100%;
  .lang {
    cursor: pointer;

    img {
      width: 30px;
    }
    span {
      font-size: 14px;
    }
    &.selected {
      // background: #efefef;
      span {
        text-decoration: underline;
      }
    }
  }
}
</style>
