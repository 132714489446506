<template>
  <section class="emsps">
    <v-stepper v-model="side">
      <v-stepper-items>
        <v-stepper-content step="2">
          <div class="selected-emsp">
            <section class="title d-flex justify-space-between">
              <h4 class="mb-2">{{ emspInfos.name }}</h4>
              <img
                @click="hideInfos"
                src="@/assets/map/ic_close.svg"
                alt="icone fermeture"
                class="close"
              />
            </section>
            <section class="emsp-content">
              <div
                v-if="emspInfos.price_description"
                v-html="emspInfos.price_description"
                class="emsp-text"
              ></div>
            </section>
            <div class="actions d-flex">
              <v-btn
                :href="emspInfos.signup"
                target="_blank"
                dark
                small
                tile
                class="mr-4"
                >{{ $t("emsp.register") }}</v-btn
              >
              <v-btn
                :href="emspInfos.page"
                target="_blank"
                dark
                small
                tile
                class="mr-4"
                >{{ $t("emsp.details") }}</v-btn
              >
            </div>
            <!-- <v-btn @click="removeEmsp" outlined small tile>{{
        $t("emsp.back")
      }}</v-btn> -->
          </div>
        </v-stepper-content>

        <v-stepper-content step="1">
          <v-row class="active-emsp pl-4">
            <v-col
              cols="6"
              v-for="emsp in emsps"
              :key="emsp.emsp_id"
              class="emsp-container"
            >
              <EMSP
                :emsp="emsp"
                :visible="emsp.isVisible"
                @clicked-emsp="selectEmsp(emsp)"
                @show-infos="showInfos(emsp)"
                @remove-emsp="removeEmsp"
              ></EMSP>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </section>
</template>

<script>
import EMSP from "@/components/map/filter/EMSP";
import { mapGetters } from "vuex";
export default {
  props: ["emsps"],
  components: {
    EMSP
  },
  data() {
    return {
      selectedEmsp: {},
      emspInfos: {},
      side: 1
    };
  },
  computed: {
    emspsOption() {
      return this.$store.getters["emsp/emspOption"];
    },
    ...mapGetters("search", ["results"])
  },

  methods: {
    removeEmsp() {
      this.$store.commit("station/SET_SELECTED_EMSP", null);
      this.selectedEmsp = {};
      /** On ne recentre la carte seulement si l'utilisateur n'a pas de * recherche
d'addresse en cours */

      if (!this.results.length) {
        this.$emit("emsp-center", null);
      }
    },
    hideInfos() {
      this.emspInfos = {};
      this.side = 1;
    },
    selectEmsp(emsp) {
      this.$store.commit("station/SET_SELECTED_EMSP", emsp);
      // this.emspsOption.forEach(emspOption => {
      //   let option = emspOption.acf;
      //   if (emsp.emsp_id === option.slug) {
      //     // emsp.abonnement = option.abonnement;
      //     // emsp.badge = option.prix_badge;
      //     // emsp.transaction = option.prix_transactions;

      //     /** On ne recentre la carte seulement si l'utilisateur n'a pas de
      //      * recherche d'addresse en cours
      //      */

      //     if (option.center && !this.results.length) {
      //       this.$emit("emsp-center", option.center);
      //     }
      //   }
      // });
    },
    showInfos(emsp) {
      this.emspsOption.forEach(emspOption => {
        let option = emspOption.acf;
        if (emsp.emsp_id === option.slug) {
          // emsp.abonnement = option.abonnement;
          // emsp.badge = option.prix_badge;
          // emsp.transaction = option.prix_transactions;
          if (option.center && !this.results.length)
            this.$emit("emsp-center", option.center);
        }
      });
      this.emspInfos = emsp;
      this.side = 2;
    }
  }
};
</script>

<style lang="scss">
.large-emsps {
  width: 420px !important;
}
.overlay .emsps {
  transition: 0.2s transform;
  scrollbar-width: none;
  scrollbar-color: light;
  padding: 12px;
  position: relative;
  .v-stepper {
    box-shadow: none;
  }
  .v-stepper__content {
    padding: 0;
  }
  .col {
    padding: 6px;
  }
  .selected-emsp {
    border-radius: 10px;
    overflow: hidden;
    background: 0 0;
    box-shadow: none;
    width: 100%;
    background: rgba(231, 231, 231, 0.884);
    padding: 1em;
    min-height: 40px;
    h4 {
      // color: #fff200;
      font-weight: 600;
      margin-bottom: 0;
      font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif;
    }
    .close {
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
    .emsp-content {
      height: auto;
      padding: 0em 0 1em;
      // color: #fff;
      .v-btn__content {
        color: white !important;
      }
      & > ul {
        list-style: none;
        // color: #fff;
        padding: 0;
        margin: 0;
        li {
          margin: 0.5em 0;
          a {
            text-decoration: none;
            display: flex;
            align-items: center;
          }
          img {
            width: 1em;
            height: 1em;
            margin-right: 0.5em;
            background: #fff200;
            border-radius: 50%;
          }
          span {
            font-weight: 600;
          }
        }
        .partenaire {
          display: flex;
          font-size: 0.9em;
          align-items: center;
          i {
            // color: #fff;
            font-size: 0.75rem;
            margin-right: 1em;
          }
          p {
            // color: #fff;
            margin: 0;
          }
        }
      }
    }
  }
  .simplebar-scrollbar::before {
    background-color: #2c2c32;
  }
  .active-emsp {
    width: 100%;
  }
}
</style>
