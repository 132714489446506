<template>
  <v-app v-if="!isEmspsLoading">
    <router-view />
  </v-app>
</template>

<script>
import "simplebar/dist/simplebar.min.css";
import "vue-select/dist/vue-select.css";
export default {
  async created() {
    //   /* window.tarteaucitron.user.multiplegtagUa = [''];
    //   (window.tarteaucitron.job = window.tarteaucitron.job || []).push('multiplegtag');*/
    //   (window.tarteaucitron.job = window.tarteaucitron.job || []).push(
    //     "googlemapssearch"
    //   );

    // window.tarteaucitron.init({
    //   privacyUrl: "" /* Privacy policy url */,

    //   hashtag: "#tarteaucitron" /* Open the panel with this hashtag */,
    //   cookieName: "tarteaucitron" /* Cookie name */,

    //   orientation: "bottom" /* Banner position (top - bottom) */,
    //   //"showAlertSmall": true, /* Show the small banner on bottom right */
    //   // "cookieslist": true, /* Show the cookie list */

    //   adblocker: false /* Show a Warning if an adblocker is detected */,
    //   DenyAllCta: true /* Show the deny all button */,
    //   AcceptAllCta: true /* Show the accept all button when highPrivacy on */,
    //   highPrivacy: true /* Disable auto consent */,
    //   handleBrowserDNTRequest: false /* If Do Not Track == 1, disallow all */,
    //   removeCredit: false /* Remove credit link */,
    //   moreInfoLink: true /* Show more info link */,
    //   useExternalCss: false /* If false, the tarteaucitron.css file will be loaded */,
    //   cookieDomain: "alizecharge.com" /* Shared cookie for subdomain website */,

    //   readmoreLink:
    //     "/cookiespolicy" /* Change the default readmore link pointing to tarteaucitron.io */,

    //   mandatory: false /* Show a message about mandatory cookies */
    // });
    await this.$store.dispatch("emsp/getAllEMSPs");
    // await this.$store.dispatch("emsp/getEmspOption");
    // let lang = this.$root.$i18n.locale.split("-")[0];
    // let routePath = this.$route.path;
    // this.$router.push({ params: { lang }, path: routePath }).catch(err => {
    // });
  },
  computed: {
    isEmspsLoading() {
      return this.$store.getters["emsp/isEmspsLoading"];
    }
  }
};
</script>
<style lang="scss">
@import "@/style/_global.scss";
@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova Black"), local("Proxima-Nova-Black"),
    url("~@/assets/font/proximanova-black-webfont.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova Bold"), local("Proxima-Nova-Bold"),
    url("~@/assets/font/proximanova-bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), local("Proxima-Nova"),
    url("~@/assets/font/proximanova-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

#app {
  // background: $dark;
  // min-height: 100vh;
  font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: $red;
  .close {
    cursor: pointer;
  }
}
#tarteaucitronAlertSmall {
  border-bottom-left-radius: 6px !important;
  background: white !important;
  bottom: auto !important;
  top: 0 !important;
  #tarteaucitronManager {
    color: #212121 !important;
    font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif !important;
  }
  #tarteaucitronCookiesNumber {
    color: #212121 !important;
    font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif !important;
    font-weight: 100 !important;
    background: #f1f1f1 !important;
    padding: 6px 10px !important;
  }
}
body #tarteaucitronRoot #tarteaucitronAlertBig {
  width: auto !important;
  left: 2em !important;
  bottom: 1em !important;
  display: flex;
  border-radius: 6px !important;
  background: white;
  align-items: center !important;
  justify-content: center;
  flex-wrap: wrap !important;
  font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif !important;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1),
    1px 1px 2px 0px rgba(0, 0, 0, 0.3);
  padding-bottom: 1em !important;
  padding-top: 1.15em !important;
  padding-right: 2em;
  padding-left: 0;
  br {
    display: none !important;
  }
  #tarteaucitronDisclaimerAlert {
    width: auto !important;
    font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif !important;
    text-align: center;
    margin: 1em;
    color: #212121;
  }
  #tarteaucitronPersonalize {
    border-radius: 6px;
    font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif !important;
    background-color: #59b570;
    color: #212121 !important;
  }
  .tarteaucitronCTAButton.tarteaucitronDeny {
    border-radius: 6px;
    font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif !important;
    background-color: #eb8080;
    color: #212121 !important;
  }
  #tarteaucitronCloseAlert {
    border-radius: 6px;
    font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif !important;
    background-color: #ebc980;
    color: #212121 !important;
    padding: 5px 10px !important;
    margin-bottom: 0 !important;
  }
}
.simplebar-scrollbar::before {
  background-color: lightgrey;
}
</style>
