<template>
  <section class="filters">
    <p class="mb-0 pl-4 pb-2">Type de prise</p>
    <section class="plugs d-flex flex-wrap justify-flex-start px-4">
      <FilterSwitch
        width="22%"
        class="mb-2 mr-2"
        v-model="filters.domestic"
        :label_trad="$t('filter.home')"
        label="Home"
        @input="updateFilter"
      ></FilterSwitch>
      <FilterSwitch
        width="22%"
        class="mb-2 mr-2"
        v-model="filters.type_1"
        label="Type 1"
        @input="updateFilter"
      ></FilterSwitch>
      <FilterSwitch
        width="22%"
        class="mb-2 mr-2"
        v-model="filters.type_2"
        label="Type 2"
        @input="updateFilter"
      ></FilterSwitch>
      <FilterSwitch
        width="22%"
        class="mb-2 mr-2"
        v-model="filters.type_3"
        label="Type 3"
        @input="updateFilter"
      ></FilterSwitch>
      <FilterSwitch
        width="22%"
        class="mb-2 mr-2"
        v-model="filters.chademo"
        label="CHAdeMO"
        @input="updateFilter"
      ></FilterSwitch>
      <FilterSwitch
        width="22%"
        class="mb-2 mr-auto"
        v-model="filters.combo"
        label="Combo"
        @input="updateFilter"
      ></FilterSwitch>
    </section>

    <p class="mb-0 pl-4 pb-2">Disponibilité</p>
    <section class="availability px-4 mb-4">
      <FilterSwitch
        width="22%"
        class="mb-2"
        v-model="filters.available"
        :label_trad="$t('filter.available')"
        label="Available"
        @input="updateFilter"
      ></FilterSwitch>
    </section>
  </section>
</template>

<script>
import FilterSwitch from "@/components/map/filter/FilterSwitch";

export default {
  components: {
    FilterSwitch
  },
  props: ["isShowing"],
  data() {
    return {
      filters: {
        domestic: false,
        combo: false,
        chademo: false,
        type_3: false,
        type_2: false,
        type_1: false,
        normal: false,
        fast: false,
        available: false
      }
    };
  },
  computed: {
    // filters: {
    //   get: function() {
    //     return this.$store.getters["station/filters"];
    //   }
    // }
  },
  methods: {
    updateFilter() {
      this.$store.commit("station/SET_FILTERS", this.filters);
    }
  },
  created() {}
};
</script>

<style lang="scss">
$switch-track-opacity: 1;
.filters {
  color: black;
  h4 {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 0.9em;
    font-weight: 400;
    padding: 1em;
    background: #2c2c32;
  }

  h6 {
    font-size: 1rem;
    font-weight: 400;
    margin: 1em 2em 1em 2em;
    padding: 0 0 0.5em 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  p {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    margin-top: 0.5em;
  }
  .v-input label {
    color: black !important;
  }
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.open {
  right: 0px;
}
</style>
