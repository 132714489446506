<template>
  <div class="connector-list">
    <v-expansion-panel-header v-if="data.list.length">
      <div class="d-flex align-center">
        <span class="connecteurs mr-6">
          <span class="plug-format" v-html="data.standard.icon"></span>
        </span>
        <div class="d-flex justify-space-between tab-text pr-6">
          <span class="cp_title mr-2"
            ><b>{{ data.standard.name }}</b>
          </span>
          <span
            >{{ availableConnectors }} sur
            {{ data.list.length }} disponible(s)</span
          >
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="inside-panel d-flex flex-wrap">
        <PopupConnector
          v-for="connector in data.list"
          :key="connector.connector_emi3"
          :poisColors="poisColors"
          :isRoaming="selectedPoint.roaming"
          :connector="connector"
        ></PopupConnector>
      </div>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import PopupConnector from "@/components/map/pin/popup/Connector";
export default {
  components: { PopupConnector },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    poisColors: {
      type: Object,
      default: () => {}
    },
    selectedPoint: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    availableConnectors() {
      let available = 0;
      this.data.list.forEach(connector => {
        if (connector.status === "FREE" || connector.status === "AVAILABLE")
          available++;
      });
      return available;
    }
  }
};
</script>

<style lang="scss">
.tab-text {
  width: 100%;
}
.connector-list {
  .plug-format {
    font-family: "SCHUKO";
    font-size: 1.25em;
    text-align: center !important;
    width: 30px;
    display: block;
  }
}
</style>
