<template>
  <section
    class="input-switch d-flex align-center justify-center"
    :style="{ width: width }"
    :class="data ? 'selected' : ''"
    @click="updateValue"
  >
    <input
      type="checkbox"
      :name="'switch' + label"
      :id="'switch' + label"
      :ref="'switch' + label"
      v-model="data"
      @change="updateValue"
      style="display: none"
    />
    <section v-if="label === 'Fast'">
      <!-- <v-icon color="#888">fas fa-bolt</v-icon> -->
      <img
        src="@/assets/map/ic_fastcharge.svg"
        alt="icone borne rapide"
        class="filter-icon"
      />
      <p class="ma-0 filter-label">{{ label_trad || label }}</p>
    </section>
    <section v-else-if="label === 'Normale'">
      <!-- <v-icon color="#888">fas fa-bolt</v-icon> -->
      <img
        src="@/assets/map/ic_fast.svg"
        alt="icone borne rapide"
        class="filter-icon"
      />
      <p class="ma-0 filter-label">{{ label_trad || label }}</p>
    </section>
    <section v-else-if="label === 'Available'">
      <img
        src="@/assets/map/ic_free.svg"
        alt="icone borne disponible"
        class="filter-icon"
      />
      <!-- <v-icon color="#888">far fa-calendar-check</v-icon> -->
      <p class="ma-0 filter-label">{{ label_trad || label }}</p>
    </section>
    <section v-else class="input-box">
      <span v-html="iconHTML"></span>
      <p class="ma-0 filter-label">{{ label_trad || label }}</p>
    </section>
    <v-icon class="checked" color="#59b570" v-if="data"
      >fas fa-check-circle</v-icon
    >
  </section>
</template>

<script>
export default {
  props: ["label", "value", "switchValue", "icon", "label_trad", "width"],
  data() {
    return {
      data: null
    };
  },
  computed: {
    iconHTML() {
      switch (this.label) {
        case "Home":
          return "&#xe80d;";
        case "Type 1":
          return "&#xe80e;";
        case "Type 2":
          return "&#xe80f;";
        case "Type 3":
          return "&#xe810;";
        case "CHAdeMO":
          return "&#xe803;";
        case "Combo":
          return "&#xe80c;";
        default:
          break;
      }
    }
  },
  methods: {
    updateValue() {
      this.$refs["switch" + this.label].click();
      this.$emit("input", this.data);
    }
  }
};
</script>

<style lang="scss">
@import "@/style/_global.scss";

.input-switch {
  // width: 100%;
  position: relative;
  height: 70px;
  text-align: center;
  border-radius: 10px;
  background: rgba(231, 231, 231, 0.884);
  cursor: pointer;
  p {
    color: #888;
  }
  span {
    font-family: "SCHUKO";
    font-size: 2em;
    color: #888;
  }
  .checked {
    position: absolute !important;
    top: -5px;
    right: -5px;
    background: white;
    border-radius: 50%;
  }

  .filter-label {
    font-size: 0.5em;
    text-transform: uppercase;
  }
  .filter-icon {
    width: 25px;
    height: 25px;
  }
}
</style>
