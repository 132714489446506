<template>
  <section class="sign-in">
    <!-- <header>
      <h2>Connexion</h2>
      <svg
        width="16"
        height="9"
        viewBox="0 0 16 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L8 8L15 1"
          stroke="#F4F4F4"
          stroke-opacity="0.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </header> -->
    <ul class="emsps">
      <li v-for="emsp in emsps" :key="emsp.emsp_id" class="emsp-card">
        <div class="emsp-content-wrapper">
          <img :src="emsp.params.logo" />
          <div class="emsp-content">
            <h4 @click="goToTarrifs(emsp.page)">
              {{ emsp.name }}
            </h4>
            <p class="description">
              {{ emsp.description ? emsp.description : "No description yet" }}
            </p>
            <div class="buttons">
              <!-- <v-btn :href="emsp.signup"  small tile text  target="_blank">
              <v-icon x-small left>fas fa-user-plus</v-icon>
              {{ $t("emsp.register") }}
            </v-btn> -->
              <a
                class="button button-green"
                target="_blank"
                :href="emsp.signin"
              >
                <img src="../assets/login.svg" alt=">" />
                {{ $t("emsp.login") }}
              </a>
              <a target="_blank" :href="emsp.page + '#tarifs'" class="button">{{
                $t("emsp.infos")
              }}</a>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!-- <footer>
      <ul>
        <li>
          <ul class="sub">
            <li>
              <a
                href="https://alizecharge.com/fr/qui-sommes-nous-2/"
                target="_blank"
                rel="noopener noreferrer"
                >Qui sommes-nous ?
              </a>
            </li>
            <li>
              <a
                href="https://alizecharge.com/fr/actualites/"
                target="_blank"
                rel="noopener noreferrer"
                >Actualités</a
              >
            </li>
            <li>
              <a
                href="https://alizecharge.com/fr/documentation/"
                target="_blank"
                rel="noopener noreferrer"
                >Documentation</a
              >
            </li>
            <li>
              <a
                href="https://alizecharge.com/fr/nos-partenaires/"
                target="_blank"
                rel="noopener noreferrer"
                >Nos partenaires</a
              >
            </li>
          </ul>
        </li>
        <li>
          <ul class="sub">
            <li>
              <a
                href="https://support.alizecharge.com/support/home"
                target="_blank"
                rel="noopener noreferrer"
                >Besoin d'aide ?</a
              >
            </li>
            <li>
              <a
                href="https://alizecharge.com/fr/politique-de-confidentialite/"
                target="_blank"
                rel="noopener noreferrer"
                >Politique de confidentialité</a
              >
            </li>
            <li>
              <a
                href="https://alizecharge.com/fr/mentions-legales/"
                target="_blank"
                rel="noopener noreferrer"
                >Mentions légales</a
              >
            </li>
            <li>
              <a
                href="https://alizecharge.com/fr/cgu/"
                target="_blank"
                rel="noopener noreferrer"
                >CGU</a
              >
            </li>
          </ul>
        </li>
      </ul>
      <p>
        Crafted with ❤️ in Paris by
        <a
          href="http://thewalkingnerds.com"
          target="_blank"
          rel="noopener noreferrer"
          ><img src="@/assets/logo.svg" alt="The Walking Nerds"
        /></a>
      </p>
    </footer> -->
  </section>
</template>

<script>
export default {
  computed: {
    emsps() {
      return this.$store.getters["emsp/emsps"].sort((a, b) => {
        let orderA = a.display_order ? a.display_order : 1000; // just to make sure items that don't have display_order
        let orderB = b.display_order ? b.display_order : 1000; // are displayed after those who have it.

        return orderA - orderB;
      });
    }
  },
  methods: {
    goToTarrifs(page) {
      window.open(page, "_blank");
    }
  }
};
</script>

<style lang="scss">
.sign-in {
  header {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 43.51%, #000000 100%),
      url("../assets/sign-in-background.webp");
    margin-top: 60px;
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    h2 {
      color: white;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 35px;
      opacity: 0.8;
    }
    flex-direction: column;
    svg {
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .emsps {
    list-style: none;
    padding: 90px 120px;
    @media only screen and (max-width: 690px) {
      padding: 80px;
    }
    @media only screen and (max-width: 490px) {
      padding: 40px;
    }
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .emsp-card {
      width: 49%;
      @media only screen and (max-width: 1290px) {
        width: 100%;
      }
      height: 40vh;
      @media only screen and (max-width: 690px) {
        height: auto;
      }
      background-color: white;
      padding: 90px 70px;
      @media only screen and (max-width: 690px) {
        padding: 40px 20px;
      }

      box-sizing: border-box;
      border-radius: 20px;
      border: 2px solid #e5e5e5;
      display: flex;
      align-items: center;
      margin-bottom: 45px;

      .emsp-content-wrapper {
        display: flex;
        align-items: flex-start;
        @media only screen and (max-width: 690px) {
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
        img {
          width: 85px;
          object-fit: contain;
        }
        .emsp-content {
          margin-left: 25px;
          @media only screen and (max-width: 690px) {
            margin-left: 0;
          }
          color: #001166;

          h4 {
            font-size: 25px;
            font-weight: bold;
            font-weight: 900;
            text-transform: uppercase;
          }
          .description {
            font-weight: 400;
            font-size: 18px;
          }
          .buttons {
            display: flex;
            @media only screen and (max-width: 690px) {
              justify-content: center;
              align-items: center;
              flex-direction: column;
            }
            .button {
              &:first-child {
                margin-right: 15px;
                @media only screen and (max-width: 690px) {
                  margin-right: 0;
                  margin-bottom: 20px;
                }
              }
              background-color: #f4f4f4;
              border-radius: 5px;
              padding: 8px 20px;
              text-transform: uppercase;
              font-weight: normal;
              text-decoration: none;
              color: #001166;
              font-size: 12px;
              letter-spacing: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: max-content;
              transition: opacity 0.5s;
              &:hover {
                opacity: 0.5;
              }

              img {
                width: 12px;
                margin-right: 12px;
              }
              &.button-green {
                background-color: #59b570;
                font-weight: 700;
                color: white;
                font-weight: 900;
              }
            }
          }
        }
      }
    }
  }
  footer {
    display: flex;
    flex-direction: column;
    color: white;
    @media only screen and (max-width: 700px) {
      align-items: center;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      @media only screen and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
      &.sub {
        display: block;
        @media only screen and (max-width: 700px) {
          text-align: center;
          li {
            text-align: center;
          }
        }
        li {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        &:first-child {
          margin-right: 30px;
          @media only screen and (max-width: 700px) {
            margin-right: 0;
          }
        }
      }
      a {
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        transition: opacity 0.5s;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    background-color: #001166;
    padding: 80px 125px 60px 125px;
    box-sizing: border-box;
    @media only screen and (max-width: 700px) {
      padding: 80px 40px;
    }
    p {
      margin-top: 40px;
      margin-bottom: 0;
      a {
        transition: opacity 0.5s;
        &:hover {
          opacity: 0.5;
        }
      }
      @media only screen and (max-width: 700px) {
        align-self: center;
        text-align: center;
      }
      align-self: flex-end;
    }
  }
}
</style>
