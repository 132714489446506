import { GET_ALL_CPOS } from "../graphql/consultation";
export default {
  namespaced: true,
  state: {
    isAdmin: false
  },
  getters: {
    isAdmin(state) {
      return state.isAdmin;
    }
  },
  mutations: {
    SET_IS_ADMIN(state, val) {
      state.isAdmin = val;
    }
  },
  actions: {
    async getAllCpos() {
      const response = await this._vm.$apollo.query({
        query: GET_ALL_CPOS
      });
      // commit("SET_SINGLE_CONSULTATION", response);
      return response.data.cpo;
    }
  }
};
