import ApolloClient from "apollo-boost";
// import router from "@/router/index.js";
// import store from "@/store/index";
import "isomorphic-unfetch";

const client = new ApolloClient({
  uri: "https://client.opendata.alizecharge.com/"
});

client.defaultOptions = {
  query: {
    fetchPolicy: "no-cache"
  }
};

export default client;
