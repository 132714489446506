<template>
  <v-btn
    @click="handleGeoLoc"
    :loading="loading"
    class="around-me mt-5"
    elevation="0"
  >
    <v-icon class="mr-3">fa fa-compass</v-icon> Autour de moi</v-btn
  >
</template>
<script>
import { mapActions, mapMutations } from "vuex";
export default {
  props: ["map"],
  data() {
    return {
      geoloc: null,
      loading: false
    };
  },
  mounted() {},
  methods: {
    ...mapActions("search", ["get", "clear"]),
    ...mapMutations("search", ["SET_MARKER"]),

    handleGeoLoc() {
      this.loading = true;
      navigator.geolocation.getCurrentPosition(async e => {
        this.loading = false;
        this.map.map.flyTo(
          {
            center: [e.coords.longitude, e.coords.latitude],
            zoom: 16
          },
          { duration: 300, fromSearch: true }
        );

        await this.get({
          map: this.map,
          query: " ",
          location: {
            lng: e.coords.longitude,
            lat: e.coords.latitude
          }
        });

        this.$emit("search");
      });
    }
  }
};
</script>
<style lang="scss">
.around-me {
  z-index: 99;
}
</style>
