<template>
  <section class="mapbox">
    <Overlay
      v-if="isMounted && displayOverlay"
      :redirect="redirect"
      :emsps="emsps"
      :emspOption="emspOption"
      :poisColors="poisColors"
      :parentMapStr="'map'"
      :register="register"
      :mapObject="$refs.map"
    ></Overlay>
    <!-- <Overlay
      v-if="isMounted && selectedEmsp"
      :redirect="emspNetwork && emspNetwork.emsp ? emspNetwork.emsp.page : ''"
      parentMapStr="mapEmsp"
   
    ></Overlay> -->
    <section class="right">
      <Popup
        v-if="isPopupOpen"
        :selectedPoint="selectedPoint"
        @close-popup="isPopupOpen = false"
        @selected-emsp="setSelectedEmsp"
      ></Popup>
    </section>
    <!-- center the map on france, with zoom to fill the map, pitch tilts the map  -->
    <MglMap
      ref="map"
      :mapStyle.sync="mapStyle"
      :accessToken="accessToken"
      @idle="e => (isMounted ? null : mapLoaded(e))"
      @moveend="filterVisibleData"
      :center="center"
      :zoom="zoom"
      :pitch="pitch"
    >
      <MglNavigationControl position="bottom-right" />

      <MglGeojsonLayer
        ref="roaming-alt"
        :source="geoJsonSourceRoamingAlt"
        sourceId="roaming-alt"
        layerId="unclustered-roaming-alt"
        :layer="roamingAlt"
      />
      <MglGeojsonLayer
        ref="alt"
        :source="geoJsonSourceAlt"
        sourceId="alt"
        layerId="unclustered-point-alt"
        :layer="unclusteredAlt"
      />
      <MglGeojsonLayer
        :source="geoJsonSource"
        sourceId="stations"
        layerId="clusters"
        :layer="clusters"
        @click="clusterZoom"
        @mouseenter="e => cursor(e, 'pointer')"
        @mouseleave="e => cursor(e, 'initial')"
      />
      <MglGeojsonLayer
        :source="geoJsonSource"
        sourceId="stations"
        layerId="cluster-count"
        :layer="count"
      />

      <MglGeojsonLayer
        :source="geoJsonSource"
        sourceId="stations"
        layerId="unclustered-point"
        :layer="unclustered"
        @click="showPopup($event, false)"
        @mouseenter="e => cursor(e, 'pointer')"
        @mouseleave="e => cursor(e, 'initial')"
      />

      <MglGeojsonLayer
        :source="roamingSource"
        sourceId="roaming"
        layerId="clusters-roaming"
        :layer="clustersRoaming"
        @click="clusterZoom"
        @mouseenter="e => cursor(e, 'pointer')"
        @mouseleave="e => cursor(e, 'initial')"
      />
      <MglGeojsonLayer
        :source="roamingSource"
        sourceId="roaming"
        layerId="cluster-count-roaming"
        :layer="countRoaming"
      />

      <MglGeojsonLayer
        :source="roamingSource"
        sourceId="roaming"
        layerId="unclustered-point-roaming"
        :layer="unclusteredRoaming"
        @click="showPopup($event, true)"
        @mouseenter="e => cursor(e, 'pointer')"
        @mouseleave="e => cursor(e, 'initial')"
      />
      <MglGeojsonLayer
        :source="selectedPointSource"
        layerId="selected-point"
        sourceId="selected-point"
        :layer="selectedLayer"
        @click="showPopup($event, false)"
        @mouseenter="e => cursor(e, 'pointer')"
        @mouseleave="e => cursor(e, 'initial')"
      />
    </MglMap>
  </section>
</template>

<script>
import "mapbox-gl/dist/mapbox-gl.css";
import Mapbox from "mapbox-gl";
import Popup from "@/components/map/pin/Popup";
import FilterPanel from "@/components/map/filter/Filters";
import EMSPS from "@/components/map/filter/EMSPS";
import Search from "@/components/map/filter/Search";
import pin from "@/assets/map/pin-sdf.png";
import LangSwitch from "@/components/LangSwitch";
import redirectTo from "@/mixins/redirectTo";
import Overlay from "@/components/map/Overlay";
import EventBus from "@/utils/eventBus.js";
import {
  MglMap,
  MglNavigationControl,
  MglGeolocateControl,
  MglGeojsonLayer
} from "vue-mapbox";

import * as turf from "@turf/turf";
import filterMixin from "@/mixins/filter.js";
import { mapGetters } from "vuex";
export default {
  components: {
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    MglGeojsonLayer,
    Popup,
    FilterPanel,
    EMSPS,
    Search,
    LangSwitch,
    Overlay
  },
  props: {
    isLoading: {
      type: Boolean
    },
    markers: {
      default: null
    },
    displayOverlay: {
      type: Boolean,
      default: true
    },
    emspOption: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  mixins: [redirectTo, filterMixin],
  data() {
    let iconColorFilter = [
      "case",
      ["in", "FREE", ["get", "status"]],
      "#59b570",
      ["==", "AVAILABLE", ["get", "status"]],
      "#59b570",
      ["in", "IN_USE", ["get", "status"]],
      "#1f73b4",
      ["in", "BLOCKED", ["get", "status"]],
      "#1f73b4",
      ["in", "UNAVAILABLE", ["get", "status"]],
      "#666666",
      ["in", "RESERVED", ["get", "status"]],
      "#1f73b4",
      ["in", "CHARGING", ["get", "status"]],
      "#1f73b4",
      "#666666"
    ];
    let iconOpacityFilter = [
      "case",
      ["in", "FREE", ["get", "status"]],
      1,
      ["==", "AVAILABLE", ["get", "status"]],
      1,
      ["in", "IN_USE", ["get", "status"]],
      0.2,
      ["in", "BLOCKED", ["get", "status"]],
      0.2,
      ["in", "UNAVAILABLE", ["get", "status"]],
      0.2,
      ["in", "RESERVED", ["get", "status"]],
      0.2,
      ["in", "CHARGING", ["get", "status"]],
      0.2,
      1
    ];
    return {
      isMounted: false,
      mapisLoaded: false,
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapStyle: "mapbox://styles/mapbox/streets-v11",
      center: [2.2, 46.2], // [lon, lat]
      zoom: 6,
      register: false,
      pitch: 40,
      clusters: {
        id: "clusters",
        type: "circle",
        filter: ["has", "point_count"],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          "circle-color": "#59b570",
          "circle-radius": ["step", ["get", "point_count"], 15, 10, 22, 30, 34]
        }
      },
      count: {
        id: "cluster-count",
        type: "symbol",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 14,
          "text-allow-overlap": true
        },
        paint: {
          "text-color": "#ffffff"
        }
      },
      unclustered: {
        id: "unclustered-point",
        layout: {
          "icon-image": "pin",
          "icon-padding": 5,
          "icon-size": {
            stops: [
              [0, 0.3],
              [9, 0.3],
              [12, 0.4],
              [15, 0.5]
            ]
          },
          "icon-rotate": 0,
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "icon-ignore-placement": true
        },

        type: "symbol",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "icon-color": iconColorFilter,
          "icon-opacity": iconOpacityFilter
        }
      },
      selectedLayer: {
        id: "selected-point",
        layout: {
          "icon-image": "pin",
          "icon-padding": 5,
          "icon-size": 0.5,
          "icon-rotate": 0,
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "icon-ignore-placement": true
        },
        type: "symbol",
        filter: ["!", ["has", "point_count"]],
        paint: { "icon-color": "blue" }
      },

      clustersRoaming: {
        id: "clusters-roaming",
        type: "circle",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#59b570",
          "circle-radius": {
            property: "point_count",
            type: "interval",
            stops: [
              [10, 15],
              [30, 22]
            ]
          },
          "circle-pitch-alignment": "viewport"
        }
      },
      countRoaming: {
        id: "cluster-count-roaming",
        type: "symbol",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 14,
          "text-allow-overlap": true
        },
        paint: {
          "text-color": "#333333"
        }
      },
      unclusteredRoaming: {
        id: "unclustered-point-roaming",
        type: "symbol",
        filter: ["!has", "point_count"],
        layout: {
          "icon-image": "pin",
          "icon-padding": 5,
          "icon-size": {
            stops: [
              [0, 0.3],
              [9, 0.3],
              [12, 0.4],
              [15, 0.5]
            ]
          },
          "icon-rotate": 0,
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "icon-ignore-placement": true
        },
        paint: {
          "icon-color": iconColorFilter
        }
      },
      unclusteredAlt: {
        id: "unclustered-point-alt",
        type: "circle",
        paint: {
          "circle-color": iconColorFilter,
          "circle-opacity": 0.2,
          "circle-radius": 6,
          "circle-pitch-alignment": "viewport"
        }
      },
      roamingAlt: {
        id: "unclustered-roaming-alt",
        type: "circle",
        paint: {
          "circle-color": iconColorFilter,
          "circle-opacity": 0.2,
          "circle-radius": 6,
          "circle-pitch-alignment": "viewport"
        }
      },
      showFilters: false,
      // data visible on viewport
      visibleData: [],
      filterValues: {
        domestic: ["SCHUKO", "DOMESTIC_F"],
        type_1: "TYPE_1_ATCH",
        type_2: ["TYPE_2", "TYPE_2_ATCH", "IEC_62196_T2"],
        type_3: "TYPE_3",
        combo: ["CCS", "IEC_62196_T2_COMBO"],
        chademo: "CHADEMO",
        normal: "normale",
        fast: "rapide",
        available: ["FREE", "AVAILABLE"]
      },
      isPopupOpen: false,
      selectedPoint: {},
      network: [],
      roamings: [],
      noZoom: false,
      selectedResult: null
      // isPopupInfoOpen: false
    };
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
    let { coords, register } = this.$route.query;
    if (register) {
      this.register = register === "1" && true;
    }
    if (coords) {
      coords = coords.split(",");
      if (coords?.length) {
        this.center = coords;
        this.zoom = 10;
      }
    }
    EventBus.$on("click-item", this.showNearbyPopup);
  },
  computed: {
    ...mapGetters("search", ["results"]),
    filters() {
      return this.$store.getters["station/filters"];
    },
    emsps() {
      let emsps = [...this.$store.getters["emsp/emsps"]];
      if (this.visibleEmsps.length) {
        return emsps.map(emsp => {
          emsp.isVisible = this.visibleEmsps.find(
            item => item.emsp_id === emsp.emsp_id
          );
          return emsp;
        });
      } else
        return emsps.map(emsp => {
          emsp.isVisible = true;
          return emsp;
        });
    },
    selectedEmsp() {
      return this.$store.getters["station/selectedEmsp"];
    },
    redirect() {
      return this.selectedEmsp ? null : "https://alizecharge.com";
    },
    visibleEmsps() {
      let visibleNetwork = [];
      if (this.selectedEmsp) visibleNetwork.push(this.selectedEmsp.emsp_id);
      visibleNetwork = [
        ...this.visibleData
          .filter(point => JSON.parse(point.properties.emsps).length !== 0)
          .map(point => {
            return JSON.parse(point.properties.emsps);
          })
      ];
      visibleNetwork = [].concat.apply([], visibleNetwork);
      const result = [];
      visibleNetwork.map(network => {
        if (!result.find(item => item.emsp_id === network.emsp_id))
          result.push(network);
      });
      return result;
    },
    stations() {
      return this.$store.getters["station/stations"];
    },
    formatedJSON() {
      let jsonObj = { type: "FeatureCollection", features: this.stations };
      return jsonObj;
    },
    geoJsonSource() {
      return {
        type: "geojson",
        data: this.formatedJSON,
        cluster: true,
        generateId: true,
        clusterMaxZoom: 11, // Max zoom to cluster points on
        clusterRadius: 100 // Radius of each cluster when clustering points (defaults to 50)
      };
    },
    selectedPointSource() {
      return {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: this.selectedResult ? [this.selectedResult] : []
        },
        generateId: true,
        clusterMaxZoom: 11, // Max zoom to cluster points on
        clusterRadius: 100 // Radius of each cluster when clustering points (defaults to 50)
      };
    },

    // data for alt layer
    geoJsonSourceAlt() {
      return {
        type: "geojson",
        generateId: true,
        data: this.formatedJSON,
        buffer: 0
      };
    },
    // data for roaming alt layer
    geoJsonSourceRoamingAlt() {
      return {
        type: "geojson",
        data: this.roamingJSON,
        buffer: 0
      };
    },
    // data for roaming layer
    roamingSource() {
      return {
        type: "geojson",
        data: this.roamingJSON,
        cluster: true,
        clusterMaxZoom: 11, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      };
    },
    // data form roaming source
    roamingJSON() {
      return {
        type: "FeatureCollection",
        features: this.$store.getters["station/roamings"]
      };
    },
    emspNetwork() {
      return this.$store.getters["station/emspNetwork"];
    },

    poisColors() {
      if (this.selectedEmsp) {
        return this.selectedEmsp.params.colors;
      } else return { roaming: "#fff200", pois: "#59b570", text: "#141417" };
    }
  },
  methods: {
    mapLoaded(e) {
      // add pin image to the map for unclestered layers
      e.map.loadImage(pin, (error, image) => {
        //if (error) throw error;
        e.map.addImage("pin", image, { sdf: true });
      });

      this.isMounted = true;
      this.$emit("mounted", e.map);
      if (this.selectedEmsp) {
        this.emspFocus(this.selectedEmsp);
      }
    },
    // zooms on a cluster when clicked
    async clusterZoom(e) {
      // e.component represents the layer
      const features = e.component.getRenderedFeatures(e.mapboxEvent.point);
      const clusterId = features[0].id;
      e.component.getClusterExpansionZoom(clusterId).then(zoom => {
        this.centerElement(e, zoom);
      });
    },
    async showPopup(e, roaming) {
      const features = e.component.getRenderedFeatures(e.mapboxEvent.point);
      this.isPopupOpen = false;
      if (this.isPopupInfoOpen) {
        this.setSelectedEmsp(null, false);
        this.isPopupInfoOpen = false;
      }

      // get all charging point from this location
      await this.$store.dispatch(
        "station/getOnePin",
        features[0].properties.id
      );
      let emsps = JSON.parse(features[0].properties.emsps);
      let emsp = emsps.find(item => !item.is_roaming);
      this.selectedPoint = {
        roaming: roaming,
        emsp
      };
      this.isPopupOpen = true;
      this.centerElement(e);
    },
    async showNearbyPopup(payload) {
      this.isPopupOpen = false;
      // get all charging point from this location
      await this.$store.dispatch(
        "station/getOnePin",
        payload.feature.properties.id
      );
      this.selectedResult = payload.feature;

      let emsps = JSON.parse(payload.feature.properties.emsps);
      let emsp = emsps.find(item => !item.is_roaming);
      this.selectedPoint = {
        roaming: null, // ??
        emsp
      };
      this.isPopupOpen = true;
      this.$refs.map.map.easeTo({
        center: payload.feature.geometry.coordinates
      });
      // this.centerElement(this.$refs["map"].map.project(payload.point));
    },
    roamingColorFilter(roaming = false) {
      return [
        "case",
        ["in", "FREE", ["get", "status"]],
        roaming ? this.poisColors.roaming : this.poisColors.pois,
        ["in", "AVAILABLE", ["get", "status"]],
        roaming ? this.poisColors.roaming : this.poisColors.pois,
        ["in", "IN_USE", ["get", "status"]],
        "#1f73b4",
        ["in", "BLOCKED", ["get", "status"]],
        "#1f73b4",
        ["in", "RESERVED", ["get", "status"]],
        "#1f73b4",
        ["in", "CHARGING", ["get", "status"]],
        "#1f73b4",
        "#666666"
      ];
    },
    centerElement(e, zoom) {
      const features = e.component.getRenderedFeatures(e.mapboxEvent.point);
      // e.map represents the map
      e.map.easeTo({
        center: features[0].geometry.coordinates,
        ...(zoom && { zoom: zoom })
      });
    },
    cursor(e, style) {
      e.map.getCanvas().style.cursor = style;
    },
    // filters viewpoint visible data
    filterVisibleData(e) {
      this.visibleData = e.map.queryRenderedFeatures({
        layers: ["unclustered-point-alt", "unclustered-point-roaming"]
      });
    },
    setEmspCenter(center) {
      if (center) {
        this.$refs.map.map.easeTo({
          center: [center.lng, center.lat],
          zoom: 7
        });
      } else {
        this.$refs.map.map.easeTo({
          center: [2.2, 46.2],
          zoom: 6
        });
      }
    },
    setSelectedEmsp(emsp, zoom) {
      if (zoom === false) {
        this.noZoom = true;
      }
      // this.isPopupInfoOpen = true
      this.$store.commit("station/SET_SELECTED_EMSP", emsp);
    },
    setCustomPoisColor() {
      if (!this.$refs.map.map) return;
      this.$refs.map.map.setPaintProperty(
        "clusters",
        "circle-color",
        this.poisColors.pois
      );
      this.$refs.map.map.setPaintProperty(
        "clusters",
        "circle-opacity",
        this.selectedEmsp ? 0.5 : 1
      );
      this.$refs.map.map.setPaintProperty(
        "cluster-count",
        "text-color",
        this.poisColors.text
      );
      this.$refs.map.map.setPaintProperty(
        "unclustered-point",
        "icon-color",
        this.roamingColorFilter()
      );
      this.$refs.map.map.setPaintProperty(
        "unclustered-point",
        "icon-opacity",
        this.selectedEmsp ? 0.5 : 1
      );
      this.$refs.map.map.setPaintProperty(
        "clusters-roaming",
        "circle-color",
        this.poisColors.roaming
      );
      this.$refs.map.map.setPaintProperty(
        "unclustered-roaming-alt",
        "circle-color",
        this.poisColors.roaming
      );
      this.$refs.map.map.setPaintProperty(
        "cluster-count-roaming",
        "text-color",
        this.poisColors.text
      );
      this.$refs.map.map.setPaintProperty(
        "unclustered-point-roaming",
        "icon-color",
        this.roamingColorFilter(true)
      );
    },
    async emspFocus(emsp) {
      // gets all stations
      let center = {};
      let currentCenter = this.$refs.map.map.getCenter();
      currentCenter = {
        geometry: {
          type: "Point",
          coordinates: [currentCenter.lng, currentCenter.lat]
        }
      };
      if (emsp) {
        this.network = [];
        // if selected emsp use all stations for roaming, roaming gets all stations
        this.roamings = emsp.all_roaming ? [...this.stations] : [];

        // indexes to delete from roamings
        let indexes = [];
        this.network = [...this.stations].filter((feature, index) => {
          // filters network stations when all roaming
          let emspInFeature = feature.properties.emsps.find(
            item => item.emsp_id === emsp.emsp_id
          );
          if (emspInFeature && emsp.all_roaming) {
            // push index to delete
            indexes.push(index);
            return true;
            // filters network stations when not all roaming
          } else if (emspInFeature) {
            return true;
            // when not roaming push stations that have selected emsp has roaming
          } else if (
            emsp.all_roaming === false &&
            feature.properties.emsps.length
          ) {
            let roamings = feature.properties.emsps.map(emsp => {
              if (emsp.is_roaming) return emsp;
            });
            roamings.filter(item => item !== undefined);
            if (roamings.includes(emsp.emsp_id)) {
              this.roamings.push(feature);
            }
            return false;
          }
        });
        // deletes indexes from roamings
        if (indexes.length > 0) {
          for (var i = indexes.length - 1; i >= 0; i--)
            this.roamings.splice(indexes[i], 1);
        }

        let features = turf.featureCollection(this.network);
        center = turf.bbox(features);
        if (!this.noZoom) {
          setTimeout(() => {
            if (
              turf.distance(center, currentCenter) > 150 &&
              !this.results.length
            )
              this.$refs.map.map.fitBounds(center, {
                padding: 100,
                linear: true,
                maxZoom: 8,
                duration: 1000
              });
          }, 300);
        }
        this.$router.push("/" + emsp.emsp_id).catch(() => {});
      } else {
        this.network = [];
        this.roamings = this.stations;
        if (!this.noZoom) {
          center = { geometry: { type: "Point", coordinates: this.center } };
          setTimeout(() => {
            this.$refs.map.map.easeTo(
              { center: center.geometry.coordinates, zoom: 6 },
              { duration: 1000 }
            );
          }, 200);
        }
        await this.$store.dispatch("emsp/resetEmspColors");
        this.$router.push("/").catch(() => {});
      }
      /* handles setting the data sources --> */
      this.$_filter({
        filters: this.filters,
        selectedEmsp: this.selectedEmsp,
        map: this.$refs.map,
        network: emsp ? this.network : [],
        roamings: emsp ? this.roamings : this.stations
      });
      this.filterVisibleData(this.$refs.map);
      this.setCustomPoisColor();
      this.noZoom = false;
    }
  },

  watch: {
    // watch for selectedEmsp changes
    selectedEmsp: async function(emsp) {
      if (this.$refs.map.map) this.emspFocus(emsp);
    },
    // watch for switch filter changes
    filters: function(val) {
      this.$_filter({
        filters: val,
        selectedEmsp: this.selectedEmsp,
        map: this.$refs.map,
        network: this.network,
        roamings: this.roamings
      });
    }
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: "SCHUKO";
  src: url("../../assets/map/font.eot");
  src: url("../../assets/map/font.eot?#iefix") format("embedded-opentype"),
    url("../../assets/map/font.woff") format("woff"),
    url("../../assets/map/font.ttf") format("truetype");
}
.mapbox {
  width: 100%;
  height: 100%;
  .site-header {
    margin-top: 1em;
    position: relative;
    z-index: 2;
    .return {
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 1em;
      i {
        font-size: 0.8em;
        margin-right: 1em;
      }
      span {
        font-weight: 700;
        color: #141417;
        font-size: 0.8em;
      }
    }
  }
  .right {
    position: absolute;
    right: 2em;
    width: 25%;
    min-width: 310px;
    margin-top: 2em;
  }
  .mapboxgl-popup-content {
    border-radius: 8px;
    padding: 0;
    background: #202025;
    font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    width: 300px;
    font-size: 1.25em;
  }
  .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: #59b570;
  }
  .mapboxgl-popup-tip {
    border-top-color: #202025;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    margin-top: -1px;
  }
  .mapboxgl-popup-close-button {
    color: #141417;
    font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 1.75rem;
    padding: 0.15em 0.25em;
  }

  .filter-toggle {
    position: absolute;
    background-color: #2c2c32;
    z-index: 1;
    border-radius: 3px;
    right: calc(30%);
    margin-right: 3em;
    top: calc(1.5em + 1px);
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: 500;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif;
    cursor: pointer;

    img {
      width: 12px;
      margin-right: 0.5em;
    }

    span {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 420px) {
    .right {
      margin-top: calc(80px + 2em + 39px);
      width: 100%;
      right: 0;
    }
  }
}
</style>
