import {
  GET_STATION_FROM_EMI3_ID,
  GET_CP_FROM_ID,
  GET_CONNECTOR_FROM_ID
} from "@/graphql/scan";

export default {
  namespaced: true,
  state: {
    station: null,
    cp: null,
    connector: null
  },
  getters: {
    station(state) {
      return state.station;
    },
    cp(state) {
      return state.cp;
    },
    connector(state) {
      return state.connector;
    }
  },
  mutations: {
    SET_SCAN_STATION(state, data) {
      state.station = data;
    },
    SET_SCAN_CP(state, data) {
      state.cp = data;
    },
    SET_SCAN_CONNECTOR(state, data) {
      state.connector = data;
    }
  },
  actions: {
    async getStation({ commit }, {stationWhere, cpWhere}) {
      const response = await this._vm.$apollo.query({
        query: GET_STATION_FROM_EMI3_ID,
        variables: {
          stationWhere,
          cpWhere
        }
      });
      commit("SET_SCAN_STATION", response.data);
    },
    async getChargingPoint({ commit }, id) {
      const response = await this._vm.$apollo.query({
        query: GET_CP_FROM_ID,
        variables: {
          id: id
        }
      });
      commit("SET_SCAN_CP", response.data);
    },
    async getConnector({ commit }, id) {
      const response = await this._vm.$apollo.query({
        query: GET_CONNECTOR_FROM_ID,
        variables: {
          id: id
        }
      });
      commit("SET_SCAN_CONNECTOR", response.data);
    }
  }
};
