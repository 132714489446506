import gql from "graphql-tag";

export const GET_CONSULTATION_BY_ID = gql`
  query getConsultation($id: uuid!) {
    consultation(where: { id: { _eq: $id } }) {
      restricted_area
      name
      is_free_choice
      displayed_cpos
      electric_car_buyed
      electric_car_ordered
      parking
      recharge_point
      header
      rgpd
      honor
      documents
      poi_color
      colors
      cluster_text_color
      phone_restriction
      popup_header
      conditions
      has_custom_address
      consultation_stations {
        data
        date_creation
        date_modification
        id
        id_consultation
        is_free_choice
      }
      consultation_responses {
        address
        id
        custom_address
      }
    }
  }
`;
export const NEW_CONSULTATION_RESPONSE_FREE = gql`
  mutation MyMutation($object: [consultation_response_insert_input!]!) {
    insert_consultation_response(objects: $object) {
      affected_rows
    }
  }
`;
export const NEW_CONSULTATION_RESPONSE_PREDEF = gql`
  mutation MyMutation($object: [consultation_response_insert_input!]!) {
    insert_consultation_response(objects: $object) {
      affected_rows
      returning {
        custom_address
      }
    }
  }
`;

export const FIND_USER_IN_RESPONSES = gql`
  query MyQuery($mail: String, $id: uuid) {
    consultation_response(
      where: { email: { _eq: $mail }, id_consultation: { _eq: $id } }
    ) {
      id
      address
      email
    }
  }
`;
export const GET_ALL_CPOS = gql`
  query getAllCpos {
    cpo {
      cpo_id
      name
    }
  }
`;

export const INSERT_CONSULTATION = gql`
  mutation insertConsultation($consultation: [consultation_insert_input!]!) {
    insert_consultation(objects: $consultation) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export const GET_CONSULTATION_RESPONSE_STATIONS = gql`
  query getConsultationResponseStations($id_consultation: uuid!) {
    consultation_station(
      where: {
        id_consultation: { _eq: $id_consultation }
        is_free_choice: { _eq: true }
      }
    ) {
      data
      id
      date_modification
      date_creation
    }
  }
`;

export const GET_CONSULTATION_LIST = gql`
  query getConsultationList {
    consultation {
      id
      is_free_choice
      consultation_stations {
        data
        id
      }
      date_creation
      date_modification
      displayed_cpos
      id_emsp
      name
      colors
      restricted_area
      electric_car_ordered
      electric_car_buyed
      parking
      recharge_point
      header
      rgpd
      honor
      documents
      phone_restriction
      popup_header
      conditions
      has_custom_address
      poi_color
      cluster_text_color
    }
  }
`;
export const DELETE_CONSULTATION_BY_ID = gql`
  mutation deleteConsultationById($consultation_id: uuid!) {
    delete_consultation_station(
      where: { id_consultation: { _eq: $consultation_id } }
    ) {
      affected_rows
    }
    delete_consultation(where: { id: { _eq: $consultation_id } }) {
      affected_rows
    }
  }
`;

export const REMOVE_CONSULTATION_POINT = gql`
  mutation removeConsultationPoint($consultation_id: uuid!, $point_id: Int!) {
    delete_consultation_station(
      where: {
        _and: [
          { id_consultation: { _eq: $consultation_id } }
          { id: { _eq: $point_id } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_CONSULTATION_BY_ID = gql`
  mutation updateConsultationById(
    $consultation: [consultation_insert_input!]!
    $points: [consultation_station_insert_input!]!
  ) {
    insert_consultation(
      objects: $consultation
      on_conflict: {
        constraint: consultation_pkey
        update_columns: [
          is_free_choice
          displayed_cpos
          restricted_area
          id_emsp
          name
          recharge_point
          parking
          electric_car_buyed
          electric_car_ordered
          header
          rgpd
          honor
          documents
          poi_color
          cluster_text_color
          phone_restriction
          popup_header
          conditions
          has_custom_address
          colors
        ]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
    insert_consultation_station(
      objects: $points
      on_conflict: {
        constraint: consultation_station_pkey
        update_columns: [is_free_choice, data]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
