import gql from "graphql-tag";

// exemple of graphql query
const GET_ALL_EMSPS = gql`
  query getAllEMSPs {
    emsp(where: { is_public: { _eq: true } }, order_by: { name: asc }) {
      emsp_id
      price_description
      price
      display_order
      name
      # logo_url
      url_logo
      page
      params
      description
      # source
      # cpos {
      #   cpos
      # }
      # roaming_cpos {
      #   cpos
      # }
      support
      all_roaming
      signup
      signin
    }
  }
`;

const GET_ONE_EMSP = gql`
  query getOneEmsp($id: String!) {
    emsp(where: { emsp_id: { _eq: $id } }) {
      emsp_id
      name
      # logo_url
      url_logo
      params
      page
      # source
      # cpos {
      #   cpos
      # }
      # roaming_cpos {
      #   cpos
      # }
      support
      all_roaming
      signup
      signin
    }
  }
`;

export { GET_ALL_EMSPS, GET_ONE_EMSP };
