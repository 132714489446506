<template>
  <div class="geocoder">
    <div
      class="geocoder-container d-flex justify-space-between align-center pr-4"
    >
      <input
        type="text"
        name="geocoder-input"
        ref="geocoder-input"
        :placeholder="$t('map.adress')"
      />
      <img
        v-if="isRemoveShowing"
        src="@/assets/map/ic_close_grey.svg"
        @click="resetMarker"
        class="icon"
        alt="icone fermeture"
      />
    </div>
  </div>
</template>
<script src=""></script>
<script>
import { mapActions, mapMutations } from "vuex";
import * as turf from "@turf/turf";
export default {
  props: {
    map: {
      type: Object,
      default: () => {}
    },
    blueMarker: {
      type: Boolean,
      default: false
    },
    draw: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      autocomplete: null,
      isRemoveShowing: false,
      query: null
    };
  },
  mounted() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs["geocoder-input"]
    );
    this.autocomplete.setComponentRestrictions({
      country: ["fr"]
    });
    this.autocomplete.setFields(["address_components", "geometry"]);
    let adress = "";
    this.autocomplete.addListener("place_changed", async e => {
      var place = this.autocomplete.getPlace();
      if (!place) {
        this.removeMarker([2.2, 46.2]);
        this.$emit("clear");
      } else {
        var center = [
          place.geometry.location.lng(),
          place.geometry.location.lat()
        ];
        this.flyTo(center, place);
        await this.get({
          location: {
            lng: place.geometry.location.lng(),
            lat: place.geometry.location.lat()
          },
          query: this.$refs["geocoder-input"].value,
          map: this.map
        });
        this.$emit("search");
        this.removeMarker();
        this.isRemoveShowing = true;
      }
    });
  },
  methods: {
    ...mapActions("search", ["get"]),
    ...mapMutations("search", ["SET_MARKER"]),
    flyTo(center, place) {
      let point = turf.point(center);
      let buffered = turf.buffer(point, 1.6, {
        units: "kilometers"
      });
      let bbox = turf.bbox(buffered);

      this.map.map.fitBounds(
        bbox,
        { duration: 3000 },
        {
          fromSearch: true
        }
      );
    },

    removeMarker(center) {
      if (this.blueMarker) var el = document.querySelector(".blue-marker");
      else
        var el =
          document.querySelector(".marker-map") ||
          document.querySelector(".marker");
      if (!el) return;
      else {
        if (el) el.remove();

        if (center) {
          this.map.map.flyTo(
            {
              center: center,
              zoom: 6
            },
            { duration: 300 }
          );
        }
      }
      this.isRemoveShowing = false;
    },
    resetMarker() {
      this.$refs["geocoder-input"].value = "";
      this.autocomplete.set("place", null);
      this.isRemoveShowing = false;
    }
  }
};
</script>
<style lang="scss">
.geocoder {
  width: 100%;
  font-size: 1em !important;
  height: 39px;
  padding-right: 0.5em;
  z-index: 2;
  position: relative;
  background-color: white;
  border-radius: 6px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.33);
  margin-bottom: 20px;

  input {
    // background-color: #2c2c32;
    z-index: 1;
    padding: 10px 20px;
    font-size: 0.85em;
    align-items: center;
    justify-content: center;
    font-family: "Proxima Nova", "Noto Sans", Helvetica, Arial, sans-serif;
    cursor: pointer;
    border: 0;
    width: 90%;
  }
  .icon {
    width: 15px;
    height: 15px;
    // position: absolute !important;
    // z-index: 5;
    // top: calc(50% - 15px);
    // right: 5px;
    cursor: pointer;
  }
}
.blue-marker {
  background-image: url("~@/assets/map/390px-Map_marker.svg.png");
  background-size: contain;
  width: 50px;
  height: 50px;
  // border-radius: 50%;
  cursor: pointer;
  z-index: 15;
}
.marker-map {
  width: 35px;
  height: 35px;

  &::before {
    width: 15px;
    height: 15px;
    background-color: #ea4335;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    display: block;
  }

  &::after {
    border: 3px solid #ea4335;
    border-radius: 30px;
    height: 25px;
    width: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    content: "";
    display: block;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}
@keyframes pulsate {
  0% {
    transform: translate(-50%, -50%) scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.2, 1.2);
    opacity: 0;
  }
}
</style>
